const initialState = {
   roomByFilter: [],
 };
 
 const roomFilterReducer = (state = initialState, action) => {
   switch (action.type) {
     case 'SET_FILTERDATA':
       return {
         ...state,
         roomByFilter: action.payload,
       };
     default:
       return state;
   }
 };
 
 export default roomFilterReducer;
 