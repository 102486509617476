import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../../assets/Login/Regency Plaza Hotels 2.png";
import LoginSlider from "./signinSlider";
import { useLogin } from "../../../services/mutations";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const logInMutation = useLogin();
  const handleLogInSubmit = (data) => {
    setLoading(true); // Start loading
    logInMutation.mutate(data, {
      onSuccess: (res) => {
        console.log(res);
        
        setErrorMessage(null);
        setLoading(false);

      },
      onError: (error) => {
        if(error){
          setErrorMessage('wrong email or password');
        }
        if (error.response.data == 'Please , Confirm your email first') {
          setErrorMessage('Please, Check your Email to Confirm your Account!');
        }
        console.log(error);
        if(error.response.data == 'Email not found'){
          setErrorMessage('email not found, please sign up first');
        }
        
        setLoading(false);
      },
    });
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&)(*]).{8,}$/,
      "Password must be at least 8 characters long and contain one uppercase letter, one lowercase letter, and one special character."
    )
    .required("Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: handleLogInSubmit,
  });

  return (
    <>
      <div className="container pt-5">
        <div className="row p-5 mt-5">
          <div className="col-md-1"></div>
          <div className="col-md-5">
            <div>
              <div className="pb-3">
                <img src={logo} alt="regency logo" />
                <h1>Login</h1>
                <p>Login to access your Globe account</p>
              </div>
              <div>
                <form onSubmit={formik.handleSubmit} className="auth-box">
                  {/* Email Field */}
                  <div className="user-box">
                    <input
                      name="email"
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="email"
                      id="email"
                    />
                    <label htmlFor="email">Email</label>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="alert alert-danger mt-2 p-2">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>

                  {/* Password Field with Show/Hide */}
                  <div className="user-box position-relative">
                <input
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="form-control"
                  placeholder="Password"
                />
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary position-absolute end-0 top-50 translate-middle-y me-2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>

              </div>
                  {formik.touched.password && formik.errors.password ? (
                      <div className="alert alert-danger mt-2 p-2">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  {/* Submit Button */}
                  <div>
                    <button type="submit" className="button-style w-100">
                    {loading ? (
                        <ClipLoader color="#ffffff" size={20} />
                      ) : (
                        "Login"
                      )}                    
                      </button>
                  </div>

                  {/* Error Message */}
                  {errorMessage && (
                    <div className="alert alert-danger mt-3">
                      {errorMessage}
                    </div>
                  )}
  {/* Forgot Password Link */}
  <div className="text-center pt-3">
                  <Link
                    to="/forget-password"
                    className="text-primary-me text-decoration-none"
                  >
                    Forgot Password?
                  </Link>
                </div>

                <div className="text-center pt-2">
                  <p>
                    Don't have an account?{" "}
                    <Link
                      to="/signup"
                      className="text-primary-me text-decoration-none"
                    >
                      Sign up
                    </Link>
                  </p>
                </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <LoginSlider />
          </div>
        </div>
      </div>
    </>
  );
}
