import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams, createSearchParams, useParams } from "react-router-dom";
import calendarImg from "../assets/shared/Frame.png";
import adultImg from "../assets/shared/Vector (15).png";
import childImg from "../assets/shared/Frame (1).png";
import DateRange from "./dateRange";
import Filters from "./filters";
import { useTranslation } from "react-i18next";
import { useRoomSearch } from "../services/mutations";
import { useDispatch, useSelector } from "react-redux";
import { setRoomFilterData } from "../store/actions/roomFilterDataActions";
import './bookRoom.css'
const BookRoom = () => {
  const { i18n } = useTranslation();
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [defaultStartDate, setDefaultStartDate] = useState(0);
  const [defaultEndDate, setDefaultEndDate] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dates, setDates] = useState({});
  const [bookingDetails, setBookingDetails] = useState({
    lang: i18n.language,
    hotelId: hotelId,
    adult: parseInt(adults),
    child: parseInt(children),
    startDate: dates.startDate ? dates.startDate.toISOString().split("T")[0]: defaultStartDate,
    endDate: dates.endDate ? dates.endDate.toISOString().split("T")[0]: defaultEndDate,
  });
  useEffect(() => {
    const today = new Date();
    today.setDate(today.getDate());
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate()  + 1);

    setDefaultStartDate(today.toISOString().split("T")[0]);
    setDefaultEndDate(tomorrow.toISOString().split("T")[0]);
  }, []);

  const storeDates = useSelector((state) => state.date.dates);
//console.log('storeDates', storeDates);

// Update booking details when adults, children, or dates change
  useEffect(() => {
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      adult: parseInt(adults),
      child: parseInt(children),
      startDate: dates.startDate ? dates.startDate.toISOString().split("T")[0] : defaultStartDate,
      endDate: dates.endDate ? dates.endDate.toISOString().split("T")[0] : defaultEndDate,
    }));
  }, [adults, children, dates,defaultEndDate,defaultStartDate]);

  // Toggle calendar visibility
  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  // Handle date selection (from DateRange component)
  const handleDateSelection = (state) => {
    //console.log("state", state);

    setDates(state[0]);


  };

  const { mutate } = useRoomSearch();

  // Handle form submission
  const handleSubmit = () => {
     mutate(bookingDetails,{
      onSuccess:(res)=>{
        console.log(res);
          // Update query parameters in the current path
    navigate({
      pathname: window.location.pathname,
      search: createSearchParams({
        startDate:  dates.startDate ? dates.startDate.toISOString().split("T")[0]: defaultStartDate,
        endDate:  dates.endDate ? dates.endDate.toISOString().split("T")[0]: defaultEndDate,
      }).toString(),
    });
      },onError:(err)=>{
console.log(err, 'useRoomSearch errrrrrrrrror');

      }
     });
    console.log("booking", bookingDetails);
  };

  // Function to handle filter changes
  const handleFilterChange = (data) => {
    // setFilterData(data);
    setBookingDetails((prevDetails) => ({
      ...prevDetails,
      hotelId: data.selectedHotel
        ? parseInt(data.selectedHotel)
        : prevDetails.hotelId,
    }));
    // console.log('booking',bookingDetails);

    if (
      bookingDetails.adult > 0 &&
      bookingDetails.startDate != null &&
      bookingDetails.endDate != null &&
      parseInt(data.selectedHotel) != 0
    ) {
      handleSubmit();
    }
  };
     useEffect(() => {
        document.title = "Regency Plaza Aqua Park - Book Room";
      }, []);
  return (
    <>
      <div className=" py-5 bookRoom d-flex align-items-center justify-content-center mb-5 ">
        <div className="container pt-5 mt-5 pt-md-0 mt-md-0 ">
        <div className="p-4 layer2 rounded ">
          <h2 className="text-center mb-2">Book a Room</h2>
          <p className="text-center mb-4">
            Discover the perfect space for you!
          </p>

          <div className="row justify-content-between align-items-center">
            {/* Date Selection */}
            <div className="col-12 col-md-5 mb-3 cursor-pointer position-relative">
              <label className="form-label">Date</label>
              <div
                className="rounded bg-white d-flex align-items-center justify-content-center px-3"
                onClick={handleCalendarToggle}
              >
                <img
                  src={calendarImg}
                  alt="Calendar"
                  className="me-2"
                  width="20"
                />
              {storeDates?.startDate && storeDates?.endDate ? (
    <p className="pt-3">
      {new Date(storeDates.startDate).toLocaleDateString(undefined, { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })} - 
      {new Date(storeDates.endDate).toLocaleDateString(undefined, { weekday: 'long', day: 'numeric', month: 'short', year: 'numeric' })}
    </p>
  ) : (
  <p className="pt-3">{defaultStartDate} - {defaultEndDate}</p>
  )}
              </div>
              {/* Conditionally render DateRange component */}
              {showCalendar && (
                <div className="mt-2 position-absolute z-index border shadow">
                  <DateRange onDateSelect={handleDateSelection} />
                </div>
              )}
            </div>

            {/* Person Selection */}
            <div className="col-12 col-md-5 mb-3">
              <label className="form-label">Person</label>
              <div className="d-flex justify-content-center bg-white py-2 rounded">
                {/* Adults */}
                <div className="d-flex align-items-center me-3">
                  <img src={adultImg} alt="Adult" className="me-2" width="15" />
                  <span>Adults</span>
                  <select
                    className="form-select ms-2"
                    value={adults}
                    onChange={(e) => setAdults(e.target.value)}
                  >
                    {[1, 2, 3, 4, 5].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Children */}
                <div className="d-flex align-items-center">
                  <img src={childImg} alt="Child" className="me-2" width="20" />
                  <span>Children</span>
                  <select
                    className="form-select ms-2"
                    value={children}
                    onChange={(e) => setChildren(e.target.value)}
                  >
                    {[0, 1, 2, 3, 4].map((num) => (
                      <option key={num} value={num}>
                        {num}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Book Now Button */}
            <div className="col-12 col-md-2 text-center mt-3">
              <button
                className="btn bgPrimaryColor text-white w-100 py-2"
                onClick={handleSubmit}
              >
                Find Room
              </button>
            </div>
          </div>
        </div>
        </div>
 
      </div>
      <Filters  onFilterChange={handleFilterChange} />
    </>
  );
};  

export default BookRoom;
