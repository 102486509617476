import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import logo from "../../../assets/Login/Regency Plaza Hotels 2.png";
import LoginSlider from "../signin/signinSlider";
import { useForgetPass } from "../../../services/mutations";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import './ForgetPass.css'
const Popup = ({ message, onClose }) => {
   return (
     <div className="popup-overlay">
       <div className="popup-content">
         <p className="popup-message">{message}</p>
         <button className="popup-close-btn" onClick={onClose}>
           Close
         </button>
       </div>
     </div>
   );
 };
export default function ForgetPass() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  const changePasswordMutation = useForgetPass();

  const handleLogInSubmit = ({ email }) => {
    console.log(email);
    setLoading(true);
    changePasswordMutation.mutate(email, {
      onSuccess: (res) => {
        console.log(res);
        setErrorMessage(null);
        setLoading(false);
        setPopupVisible(true);

      },
      onError: (error) => {
        if (error) {
          setErrorMessage("Wrong email");
        }
        console.log(error);
        if (error.response?.data === "Email not found") {
          setErrorMessage("Email not found, please sign up first");
        }
        setLoading(false);
      },
    });
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: handleLogInSubmit,
  });

  return (
   
    <div className="container pt-5">
      {isPopupVisible && (
        <Popup
          message="A password reset email has been sent to your email address!"
          onClose={() => setPopupVisible(false)}
        />
      )}
      <div className="row p-5 mt-5">
        <div className="col-md-1"></div>
        <div className="col-md-5">
          <div>
            <div className="pb-3">
              <img src={logo} alt="regency logo" />
              <h1>Forgot your password?</h1>
              <p>
                Don’t worry, happens to all of us. Enter your email below to
                recover your password
              </p>
            </div>
            <div>
              <form onSubmit={formik.handleSubmit} className="auth-box">
                {/* Email Field */}
                <div className="user-box">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <label htmlFor="email">Email</label>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="alert alert-danger mt-2 p-2">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>

                {/* Submit Button */}
                <div>
                  <button type="submit" className="button-style w-100">
                    {loading ? (
                      <ClipLoader color="#ffffff" size={20} />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>

                {/* Error Message */}
                {errorMessage && (
                  <div className="alert alert-danger mt-3">
                    {errorMessage}
                  </div>
                )}

                {/* Forgot Password Link */}
                <div className="text-center pt-3">
                  <Link
                    to="/login"
                    className="text-primary-me text-decoration-none"
                  >
                    Go to login?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <LoginSlider />
        </div>
      </div>
    </div>
  );
}
