import React, { useEffect } from "react";
import HomeSlider from "../homeSlider/home-slider";
import { Facilities } from "./facilites";
import { Comfort } from "./comfort";
import { Video } from "./video";
import { LuxuriousRooms } from "./luxuriousRooms";
import { HotelHighlights } from "./hotelHighlights";
import ReviewsSlider from "../sections/reviewsSlider/reviewsSlider";
import { Services } from "../sections/services/services";
import { Awards } from "./awards";
import { SwimmingPools } from "../sections/swimminPools/swimmingPools";

export default function Home() {
  useEffect(() => {
    document.title = "Regency Plaza Aqua Park";
  }, []);
  return<>
      <HomeSlider />
      <Services/>
      <Facilities />
      <Comfort />
      <Video />
     <LuxuriousRooms />
       <SwimmingPools/>
      <HotelHighlights />
      <ReviewsSlider/>
      <Awards/>
    </>
}

// تعالي ورايا قين
// Primary Languages: English, Arabic, Russian, Italian and Polish
