import React, { useState } from 'react';
import { usePostReview } from '../../../services/mutations';
import { useParams } from 'react-router-dom';

const WriteReview = ({handleReviewSubmit}) => {
   const {hotelId} = useParams()
   const user =JSON.parse(localStorage.getItem("user"))
   const reviewMutate = usePostReview()
   const [error, setError] = useState('');
  const [review, setReview] = useState({
   userId:user?.id,
   comment: '',
   rating: 0,
    hotelId: hotelId

  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setReview((prev) => ({
      ...prev,
      [name]: name === 'rating' ? parseInt(value) : value,
      userId:user.id,
      hotelId: hotelId

    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation
    if (!review.comment || review.rating === 0) {
      setError('Both comment and rating are required!');
      return;
    }
    setError('');
    console.log(review,'reviewreviewreviewreviewreviewreview');
    
    reviewMutate.mutate(review,{
      onSuccess:(res)=>{
         console.log(res);
         handleReviewSubmit(review);
      },
      onError:(err)=>{
         console.log(err);
      }
   })   
    setReview({ comment: '', rating: 0 }); // Reset the form
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="comment" className="form-label">
            Comment
          </label>
          <textarea
            id="comment"
            name="comment"
            className="form-control"
            rows="4"
            value={review.comment}
            onChange={handleChange}
            placeholder="Share your thoughts..."
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="rating" className="form-label">
            Rating
          </label>
          <select
            id="rating"
            name="rating"
            className="form-select"
            value={review.rating}
            onChange={handleChange}
            required
          >
            <option value="0">Select a rating</option>
            <option value="5">5 - Excellent</option>
            <option value="4">4 - Very Good</option>
            <option value="3">3 - Good</option>
            <option value="2">2 - Fair</option>
            <option value="1">1 - Poor</option>
          </select>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button type="submit" className="btn bgPrimaryColor text-white py-2 px-3 text-sm me-2 text-me">
          Submit Review
        </button>
      </form>
    </div>
  );
};

export default WriteReview;
