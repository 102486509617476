import React, { useEffect, useRef, useState } from 'react';
import imagebg from '../../assets/facilites/image (18).png';
import './facilites.css';
import { Link, useParams } from 'react-router-dom';
import { useGetCategories } from './services/queries';
import { useTranslation } from 'react-i18next';
import { BASE_URL } from './services/api';
import Loader from '../loading/loadingPage';


const Facilities = () => {
    const{hotelId,categoryId} = useParams()
    const { i18n } = useTranslation();
    const [show,setShow]=useState(null);
    const { data: category, error, isLoading } = useGetCategories({ hotelId, lang: i18n.language,categoryId });
    if (category) {
     console.log(category);
        
    }
    useEffect(()=>{
        if (category) {
           setShow(category[0])
        }
     },[category]);
     useEffect(()=>{
        if (show) {
            if (hotelId==1) {
                if (categoryId==4) {
                    document.title ="Beach"+ ' - Regency Plaza Aqua Park';
                }
                if (categoryId==2) {
                    document.title ="Conferences & Meetings"+ ' - Regency Plaza Aqua Park';
                }
                if (categoryId==1) {
                    document.title ="Gym & Spa"+ ' - Regency Plaza Aqua Park';
                }
                if (categoryId==3) {
                    document.title ="Shopping Area"+ ' - Regency Plaza Aqua Park';
                }
            }
            if (hotelId==2) {
                if (categoryId==4) {
                    document.title ="Beach"+ ' - Royal Regency Club';
                }
                if (categoryId==2) {
                    document.title ="Conferences & Meetings"+ ' - Royal Regency Club';
                }
                if (categoryId==1) {
                    document.title ="Gym & Spa"+ ' - Royal Regency Club';
                }
                if (categoryId==3) {
                    document.title ="Shopping Area"+ ' - Royal Regency Club';
                }
            }      
            if (hotelId==3) {
                if (categoryId==4) {
                    document.title ="Beach"+ ' - Romance Regency Club';
                }
                if (categoryId==2) {
                    document.title ="Conferences & Meetings"+ ' - Romance Regency Club';
                }
                if (categoryId==1) {
                    document.title ="Gym & Spa"+ ' - Romance Regency Club';
                }
                if (categoryId==3) {
                    document.title ="Shopping Area"+ ' - Romance Regency Club';
                }
            }   
        }
     },[show]);
     if (isLoading) {
        return <div><Loader/></div>;
      }

return<>
   <div className="overlay-container position-relative">
            {/* Overlay Content */}
<div className="backgroundTransparent position-absolute w-100 h-100">
  <div className="d-flex justify-content-center justify-content-md-start align-items-center h-100">
    <div className="col-12 col-md-6 px-4 px-md-5 text-white fade-in-animation text-center text-md-start">
      <h1 className="fs-md-7 mt-5 pt-5 mt-md-0 pt-md-0">
        {show?.name}
      </h1>
      <p className="fs-5 fs-md-5">{show?.description}</p>
      <Link
        to={`/book-room/${hotelId}`}
        className="btn btn-lg custom-btn bgPrimaryColor rounded-5 text-white mt-3 px-4 px-md-5"
      >
        Book Now
      </Link>
    </div>
  </div>
</div>


            {/* Image Container */}
            <div className="image-container ">
                <img src={BASE_URL+show?.coverUrl} className="" alt="Background" />
            </div>
        </div>
   <div className="container mt-4 pt-5">
            <div className="row">
            {show?.images?.map((image, index) => {
                    if (index % 4 === 0 || index % 4 === 3) {
                        // Big image column
                        return (
                            <div key={index} className="col-md-8 mb-4">
                                <div className="img-container big-image">
                                    <img src={BASE_URL+image.imagePath}  alt={`img-${index}`} className="img-fluid rounded" />
                                </div>
                            </div>
                        );
                    } else {
                        // Small image column
                        return (
                            <div key={index} className="col-md-4 mb-4 d-flex flex-column">
                                <div className="img-container small-image">
                                    <img src={BASE_URL+image.imagePath} alt={`img-${index}`} className="img-fluid rounded" />
                                </div>
                            </div>
                        );
                    }
                })}
            </div>
        </div>
</>


};

export default Facilities;
