import { useQuery } from "@tanstack/react-query";
import { GetAllRoomTypes, getRecentlyRoom, GetRoomByFilter, GetUser } from "./api";
import { GetHomeFacilities, GetHotelReviews, GetLuxuriousRoom, GetServices } from "./getApis";
import { getAllHotelRooms, GetHomeReviews } from "../components/facilites/services/api";

export function useGetServices(lang){
   return useQuery({
      queryKey: ['GetServices',lang],
      queryFn: () => GetServices(lang),
   })
}

export function useGetHomeFacilities(lang) {
  return useQuery({
    queryKey: ['GetHomeFacilitie', lang],
    queryFn: () => GetHomeFacilities(lang),
  });
}


export function useGetLuxuriousRoom(lang){
   return useQuery({
      queryKey: ['GetLuxuriousRoom',lang],
      queryFn:()=> GetLuxuriousRoom(lang),
   })
}

export function useRoomsTypes(lang){
   return useQuery({
      queryKey: ['rooms',lang],
      queryFn:()=> GetAllRoomTypes(lang),
   })
}

export function useGetHotelRooms({lang,hotelId}){
   return useQuery({
      queryKey: ['hotelRooms',lang,hotelId],
      queryFn:()=> getAllHotelRooms({lang,hotelId}),
   })
}

export function useGetHomeReviews(){
   return useQuery({
      queryKey: ['GetHomeReviews'],
      queryFn:()=> GetHomeReviews(),
   })
}

export function useGetRoomFilter(roomId, filterDate){
   return useQuery({
      queryKey: ['useGetRoomFilter'],
      queryFn:()=> GetRoomByFilter(roomId, filterDate),
   })
}

export function useGetHotelReviews(hotelId){
   return useQuery({
      queryKey: ['useGetRoomFilter',hotelId],
      queryFn:()=> GetHotelReviews(hotelId),
   })
}

export const useGetUser = () => {
   return useQuery({
     queryKey: ['GetUser'],
     queryFn: GetUser,
   });
 };

 export const useGetRecentlyRoom = (roomData,lang) => {
   return useQuery({
     queryKey: ['useGetRecentlyRoom',roomData,lang],
     queryFn:()=> getRecentlyRoom(roomData,lang),
   });
 };
