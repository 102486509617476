import React from 'react';
import SwimmingPool from '../../../assets/Main/makiSwimming0.png';
import { useGetServices } from '../../../services/queries';
import { useTranslation } from 'react-i18next'; 
import './services.css';
import { BASE_URL } from '../../../services/api';
import Loader from '../../loading/loadingPage';

export const Services = () => {
  const { i18n } = useTranslation();
  const storedLang = i18n.language;
  const { data: services, error, isLoading } = useGetServices(storedLang);

  if (error) {
    console.error('Error fetching services:', error); 
    return <div>Error loading services</div>;
  }

  if (isLoading) {
    return <Loader />;
  }

  // Limit to the first 4 services
  const limitedServices = services?.slice(0, 4);

  return (
    <>
      <div className='text-center text-4B86B4 pt-5'>
        <h2 className='h1'>Our Services</h2>
      </div>
      <section className="d-flex justify-content-center align-items-center container m-auto gy-4">
        {limitedServices.slice(-8)?.map((service) => (
          <div className="col-3" key={service.id}>
            <div className='servicesItemBg py-4 py-md-5 mx-1 rounded-4 text-center'>
              <div className='d-flex justify-content-center align-items-center pb-2 text-center'>
                <img 
                  style={{ width: "50px", height: "30px"}}
                  src={BASE_URL + service.imageUrl} 
                  alt={service.name} 
                />
              </div>
              <div className='text-center'>
              <h5 className='text-4B86B4'>{service.name}</h5>

              </div>
            </div>
          </div>
        ))}
      </section>
    </>
  );
};
