import React from 'react';

import { useTranslation } from "react-i18next";
import welcome from "../../assets/home/image.png";
import homeStyle from './home.module.css'
import { Link } from "react-router-dom";

export function HotelHighlights(){
  const {t} = useTranslation()
return<>
      <section className="container pt-5">
      <div
        className={`${homeStyle.bgSecondary} rounded-4 mb-5 row justify-content-center align-items-center py-5 px-3`}
      >
        <div className="col-12 col-md-6 mb-4 mb-md-0">
          <img
            className="img-fluid rounded-3 shadow"
            src={welcome}
            alt="Welcome"
          />
        </div>
        <div className="col-12 col-md-6">
          <div className="text-center text-md-start px-3 px-md-5">
            <h2 className="display-4 BigshotOneFont mb-4">
              {t("Take a brief tour")}
              <br />
              {t("through our")}
              <br />
              {t("hotel's highlights")}
            </h2>
            <p className="lead">
              {t("Prepare to embark on a virtual adventure through our hotel's offerings, where each click leads you closer to discovering the hidden gems and luxurious surprises awaiting your stay.")}
            </p>
            <div className="pt-4 ">
          <Link
                to="/book-room/1"
                className="btn bgPrimaryColor w-50 text-white rounded-5 btn-lg custom-btn block"
              >
                Book Now
              </Link>
          </div>
          </div>
        </div>
      </div>
    </section>
</>
}