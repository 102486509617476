import { useEffect, useState } from "react";
import beach from "../../assets/home/Blob 1.png";
import { BASE_URL } from "../../services/api";
import React from 'react';
import { useGetHomeFacilities } from "../../services/queries";
import animation from './animation.module.css';
import homeStyle from './home.module.css';
import { useTranslation } from 'react-i18next';
import Loader from "../loading/loadingPage";
import { Link } from "react-router-dom";

export function Comfort() {
  const { i18n } = useTranslation();
  const lang = i18n.language; 
  const { data: facilities, isLoading, isError } = useGetHomeFacilities(lang);

  if (isLoading) {
    return <div><Loader/></div>;
  }

  if (isError) {
    return <div>Error loading facilities.</div>;
  }

  return (
    <>
      <section className="container my-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 text-center text-md-start">
            <h1 className={`BigshotOneFont ${homeStyle.headerStyle} lh-base`}>
              {facilities.description}
            </h1>
            <div className="pt-4 ">
          <Link
                to="/book-room/1"
                className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn block"
              >
                Book Now
              </Link>
          </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end order-first order-md-2 pb-md-0 ">
            <div className={`${homeStyle.container1}`}>
              <div className={`${homeStyle.bordered} w-100`}>
                <img
                  className="img-fluid"
                  src={`${BASE_URL}${facilities.url}`}
                  alt={facilities.name || "Facility"}
                />
              </div>
              <div className={animation.irrelativeMovement}>
                <div className={animation.box}>
                  <div className={animation.shape}></div>
                  <div className={animation.shape}></div>
                  <div className={animation.shape}></div>
                  <h1 className={animation.h1Animation}></h1>
                </div>
              </div>
              <div className={animation.irrelativeMovementText}>
                <div className={animation.boxTitle}>
                  <div className={animation.shape2}></div>
                  <div className={animation.shape2}></div>
                  <div className={animation.shape2}></div>
                  <h1 className={animation.h1Animation}>
                    <span className={`font-monospace ${animation.fontAnimation}`}>
                      {facilities.name}
                    </span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
