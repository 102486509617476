import React, { useEffect } from 'react';
import contactStyle from './contatc.module.css'
import icon12 from '../../assets/Main/Icon (12).png';
import icon11 from '../../assets/Main/Icon (11).png';
import icon13 from '../../assets/Main/Icon (13).png';
import contact2 from '../../assets/swimming pools/6 Swimming Pools/Swimming Pool7.jpg';
import ContactForm from './contact-form';
import HotelMap from './map';

export default function Contact() {
  useEffect(() => {
    document.title = "Contact Us - Regency Plaza Aqua Park";
  }, []);

  return (
    <>
      <section>
        <div className={`d-flex justify-content-center align-items-center ${contactStyle.bgImage1}`}>
          <div className="text-center px-3 px-md-5 pt-5 mt-5 pt-md-0 mt-md-0">
            <div className="layer rounded-5 p-3 p-md-4 fade-in-animation">
              <h1 className="BigshotOneFont text-white fs-4 fs-md-7">CONTACT US</h1>
              <p className="text-white">Feel Free To Reach Us</p>
            </div>
          </div>
        </div>
      </section>

      <section className="container py-5 mt-5">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <img src={contact2} className="w-100 rounded-4" alt="Contact Us" />
          </div>
          <div className="col-12 col-md-6 text-start pt-3">
            <div className="px-3 px-md-5">
              <span className="rounded-2 bgPrimaryColor text-white py-2 px-3 d-inline-block">
                Let’s talk
              </span>
              <h2 className="h4 h-md-1 pt-3">Get In Touch With Us</h2>
              <p className="small text-md-base">
                Have questions or need assistance? Our team is here to help. Contact us to get started
                on your journey!
              </p>
              {/** Contact Details **/}
              <div className="d-flex pt-3">
                <div className="pe-3">
                  <img src={icon11} alt="Phone Icon" />
                </div>
                <div>
                  <h6 className="fs-6 fw-bold">Have Any Question?</h6>
                  <p className="mb-0 small">+20 693 710 248</p>
                  <p className="small">+20 693 710 250</p>
                </div>
              </div>

              <div className="d-flex pt-3">
                <div className="pe-3">
                  <img src={icon12} alt="Email Icon" />
                </div>
                <div>
                  <h6 className="fs-6 fw-bold">Write Email</h6>
                  <p className="mb-0 small">reservation@regencyplazasharm.net</p>
                </div>
              </div>

              <div className="d-flex pt-3">
                <div className="pe-3">
                  <img src={icon13} alt="Location Icon" />
                </div>
                <div>
                  <h6 className="fs-6 fw-bold">Visit Anytime</h6>
                  <p className="small">
                    Nabq Bay, Sharm El Sheikh, South Sinai, Egypt
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
      <HotelMap />
    </>
  );
}
