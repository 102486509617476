import React from 'react';

import footerStyle from './footer.module.css'
import logo from "../../assets/Login/Regency Plaza Hotels 2.png";
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaTiktok, FaEnvelope} from "react-icons/fa"; 

export const Footer =()=>{
   return<>
   <section className={`bg-secondary-color mt-5`}>
   <div className={` container px-3 px-md-5`}>
  <div className="row justify-content-between g-4 pt-4">
    {/* Logo and Description */}
    <div className="col-md-4  pe-md-4 text-center">
      <div className="d-flex justify-content-center mb-3">
        <img src={logo} className="pb-3" style={{ width: "85px" }} alt="regency logo" />
      </div>
      <p className="pb-0 mb-0 ">
      Spend your vacation with our activities & Hotel
      </p>
      <span className="text-sm fw-bold ">
      Discover the best space for you
      </span>
    </div>

    {/* Links Section */}
    <div className="col-md-4 text-start ps-md-5">
      <p className="textPrimaryColor pb-3">Links</p>
      <Link
      to="/swimming-pools/4"
        className="d-block pb-3 text-decoration-none text-body hover-link"
      >
      Aqua park
      </Link>
      <Link
        href="our-products"
        className="d-block pb-3 text-decoration-none text-body hover-link"
      >
        About Us
      </Link>
      <Link
        to="/about-us"
        className="d-block text-decoration-none text-body hover-link"
      >
        Contact Us
      </Link>
    </div>

    {/* Contacts Section */}
    <div className="col-md-4 text-start">
      <p className="textPrimaryColor pb-3">Contacts</p>
      <ul className="list-unstyled">
        <li  className="pb-3 text-primary-me">
         <Link className='text-primary-me' to="mailto:reservation@regencyplazasharm.net">
         reservation@regencyplazasharm.net
         </Link>
         </li>
        <li className="pb-3">
        Nabq Bay, Sharm El Sheikh, South Sinai, Egypt
        </li>
        <li className="pb-3">+20 693 710 248</li>
        <li className="pb-3">+20 693 710 250</li>
      </ul>
      <div className="row justify-content-end pe-3 pb-2 ">
                {/* Social Media Links */}
                <div className=" d-flex justify-content-end align-items-center ">
                  <div className="rounded-circle border nav-icon">
                    <Link
                      to="https://www.facebook.com/RegencyPlazaHotels"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary-me"
                    >
                      <FaFacebookF size={15} />
                    </Link>
                  </div>
                  <div className="rounded-circle border nav-icon mx-1">
                    <Link
                      to="https://www.instagram.com/regencyplazasharm/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-danger "
                    >
                      <FaInstagram size={15} />
                    </Link>
                  </div>
                  <div className="rounded-circle border nav-icon me-1">
                    <Link
                      to="https://www.tiktok.com/@regencyplazasharm?lang=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark"
                    >
                      <FaTiktok size={15} />
                    </Link>
                  </div>
                  <div className="rounded-circle border nav-icon">
                    <Link
                      to="mailto:reservation@regencyplazasharm.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark"
                    >
                      <FaEnvelope size={15} />
                    </Link>
                  </div>
                </div>
              </div>
    </div>
  </div>
</div>
   </section>
   {/* Copyright Section */}
<section className="bgPrimaryColor py-3 text-center text-secondary">
  <a href="" className="text-decoration-none text-body">
    &copy; {new Date().getFullYear()} Mubasher Technology. All rights reserved.
  </a>
</section>

<div >


</div>
   </>
}