import React, { useEffect } from 'react';
import royalRegencyStyle from './royalRegency.module.css'
import bgHotel from '../../../assets/hotels/Group 194.png'
import { Categories } from '../../sections/categories/categories'
import { RecentlyViewed } from '../../sections/recentlyViewed/RecentlyViewed'
import { AllRooms } from '../../sections/allRooms/allRooms'
import { Link, useParams } from 'react-router-dom'
import { HotelSwimmingPools } from '../../sections/swimminPools/hotelSwimmingPools'
import HotelReviews from '../../sections/hotelReview/hotelReview';
export const RoyalRegency =()=>{
   const{hotelId} = useParams()
   useEffect(() => {
      document.title = "Royal Regency Club";
    }, []);
   return<>
   <section>
      <div className={`vh-100 text-center d-flex justify-content-center align-items-center ${royalRegencyStyle.bgImage}`}>
      <div className="w-75 fade-in-animation">
            <h1 className="text-white pb-3 fs-7 BigshotOneFont">Royal Regency Club</h1>
            <div className={`py-5 rounded-5 layer2`}>
              <h4>Spend your vacation with our activities & Hotel</h4>
              <p className=''>Discover the best space for you</p>
              <Link
                to="/book-room/1"
                className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn"
              >
                Book Now
              </Link>
            </div>
          </div>
      </div>
   </section>
   <Categories hotelId={hotelId}/>
   <AllRooms/>
   <HotelSwimmingPools/>
   <section className={`${royalRegencyStyle.bgHotelRoom} mb-5`}>
  <div className="container">
    <div className="row align-items-center">
      {/* Image Section */}
      <div className="col-12 col-md-6 text-center">
        <img 
          src={bgHotel} 
          alt="bgHotel" 
          className="img-fluid mb-4 mb-md-0"
        />
      </div>
      {/* Content Section */}
      <div className="col-12 col-md-6 text-white">
        <div className="text-center text-md-start">
          <h2 className="BigshotOneFont fs-7">Royal Regency Club</h2>
          <div className="mb-4">
            <span className="border-end px-3 me-3">Sharm</span>
            <span>Great Reviews</span>
          </div>
          <div className="pe-md-5 mb-4">
            <p>
              Welcome to our grand unveiling! We are thrilled to open the doors
              of our new hotel and invite you to embark on a journey of luxury,
              comfort, and exceptional hospitality.
            </p>
          </div>
          <div>
            <Link
              to="/book-room/2"
              className="btn bgPrimaryColor rounded-5 text-white btn-lg custom-btn mb-4 mb-md-0"
            >
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

   <RecentlyViewed/>
   {/* <Offers/> */}
<HotelReviews/>

   </>
}