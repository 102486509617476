import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useConfirmEmail } from "../../../services/mutations";

export const ConfirmEmail = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [popupType, setPopupType] = useState("success");

  const { mutate } = useConfirmEmail();

  useEffect(() => {
    if (code) {
      mutate(code, {
        onSuccess: (res) => {
          setPopupMessage("Email confirmed successfully, please login!");
          setPopupType("success");
          setShowPopup(true);
          setTimeout(() => {
            setShowPopup(false);
            navigate("/login");
          }, 3000);
        },
        onError: (err) => {
          setPopupMessage(
            err.response?.data || "Email confirmation failed. Please try again."
          );
          setPopupType("error");
          setShowPopup(true);
        },
      });
    }
  }, [code, mutate, navigate]);

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      {showPopup && (
        <div className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center bg-dark bg-opacity-50">
          <div className="bg-white rounded shadow p-4 text-center">
            <button
              onClick={() => setShowPopup(false)}
              className="btn-close position-absolute top-0 end-0 me-3 mt-3"
              aria-label="Close"
            ></button>
            <div className="mb-4">
              {popupType === "success" ? (
                <i className="bi bi-check-circle-fill text-success fs-1"></i>
              ) : (
                <i className="bi bi-exclamation-circle-fill text-danger fs-1"></i>
              )}
            </div>
            <p className="fw-semibold fs-5 mb-4">{popupMessage}</p>
            {popupType === "error" && (
              <Link
                to="/auth/signup"
                className="btn btn-danger w-100"
              >
                Close
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ConfirmEmail;
