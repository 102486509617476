import React, { useEffect, useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./filter.css";
import HotelCard from "./HotelCard";
import Loader from "../components/loading/loadingPage";
// import PriceRangeSlider from "./FilterByPrice";

const Filters = ({ onFilterChange,isLoading }) => {
  const [priceRange, setPriceRange] = useState([50, 1200]);
  const [selectedHotel, setSelectedHotel] = useState("");
//console.log(isLoading);

  // Handle price range change
  const handlePriceChange = (newRange) => {
    setPriceRange(newRange);
  };

  // Handle hotel radio change
  const handleHotelChange = (e) => {
    setSelectedHotel(e.target.value);
  };
  // Effect to notify parent of changes
  useEffect(() => {
    onFilterChange({
      priceRange,
      selectedHotel,
    });
  }, [priceRange, selectedHotel]);

  // useEffect(() => {
  //   console.log({
  //     priceRange,
  //     selectedHotel,
  //   });
  // }, [priceRange, selectedHotel]);
  return (
    <>
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-3 filters-container">
            <h3>Filters</h3>
{/* <PriceRangeSlider/> */}
            {/* Price Range */}
            <div className="filter-section">
              <h4>Price</h4>
              <Slider
                range
                min={50}
                max={1200}
                defaultValue={priceRange}
                onChange={handlePriceChange}
                trackStyle={[{ backgroundColor: "#0d6efd" }]}
                handleStyle={[
                  { borderColor: "#0d6efd" },
                  { borderColor: "#0d6efd" },
                ]}
              />
              <div className="price-values">
                <span>${priceRange[0]}</span>
                <span>${priceRange[1]}</span>
              </div>
            </div>
            <hr />

            {/* Hotels */}
            <div className="filter-section">
              <h4>Hotels</h4>
              <div className="hotels-radios form-check">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hotel"
                    value="1"
                    checked={selectedHotel === "1"}
                    onChange={handleHotelChange}
                  />
                  Regency Plaza Aqua Park
                </label>
                <br />
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hotel"
                    value="2"
                    checked={selectedHotel === "2"}
                    onChange={handleHotelChange}
                  />
                  Royal Regency Plaza
                </label>
                <br />
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="hotel"
                    value="3"
                    checked={selectedHotel === "3"}
                    onChange={handleHotelChange}
                  />
                  Romance Regency Club
                </label>
              </div>
            </div>
          </div>

          <div className="m-auto col-md-9 mb-5">
            {isLoading?
            <div><Loader/></div>:<HotelCard />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Filters;
