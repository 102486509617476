import React from 'react';
import pool from "../../../assets/swimming pools/Rectangle 13.png";
import pool2 from "../../../assets/swimming pools/Rectangle 13 (1).png";
import pool3 from "../../../assets/swimming pools/Rectangle 13 (2).png";

import { Link } from "react-router-dom";
import { useState } from "react";

export const SwimmingPools = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <section className="container my-5">
        <div>
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="col-12 col-md-10">
              <h2 className="textMain">Swimming Pools</h2>
            </div>
            <div className="col-12 col-md-2 text-md-end">
              <Link
                to={`/swimming-pools/1`}
                className="fs-4 fw-semibold text-decoration-none"
                style={{
                  color: isHovered ? "#345b78" : "#4B86B4", // Change color on hover
                  transition: "color 0.3s ease", // Smooth color transition
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                VIEW ALL
              </Link>
            </div>
          </div>
          <div className="col-12 mt-3">
            <p className="text-muted">Enjoy a Refreshing Dip Across all our Hotels</p>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="shadow rounded-5 p-3 h-100">
              <img src={pool} className="w-100 rounded-5 hover-effect" alt="Regency Swimming Pools" />
              <div className="ps-2 pb-3">
                <h4 className="py-3">Regency Swimming Pools</h4>
                <Link to={`/swimming-pools/1`} className="btn bgPrimaryColor rounded-5 text-white pb-2 px-3 custom-btn">
                  More Details
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="shadow rounded-5 p-3 h-100">
              <img src={pool2} className="w-100 rounded-5 hover-effect" alt="Romance Swimming Pools" />
              <div className="ps-2 pb-3">
                <h4 className="py-3">Romance Swimming Pools</h4>
                <Link to={`/swimming-pools/3`} className="btn bgPrimaryColor rounded-5 text-white pb-2 px-3 custom-btn">
                  More Details
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4 mb-4">
            <div className="shadow rounded-5 p-3 h-100">
              <img src={pool3} className="w-100 rounded-5 hover-effect" alt="Royal Swimming Pools" />
              <div className="ps-2 pb-3">
                <h4 className="py-3">Royal Swimming Pools</h4>
                <Link to={`/swimming-pools/2`} className="btn bgPrimaryColor rounded-5 text-white pb-2 px-3 custom-btn">
                  More Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
