import axios from "axios";
import { jwtDecode } from "jwt-decode";

 export const BASE_URL = 'https://regencyapi.mubasher.com.eg';
 
 export const _axios = axios.create({baseURL: BASE_URL})

 // Function to set the token
export const setAuthToken = (token) => {
  if (token) {
    // Apply the token to every request
    _axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    // Remove the token from the headers if there's no token
    delete _axios.defaults.headers.common['Authorization'];
  }
};

 export const signIn = async(data)=>{
  const response = await _axios.post('/api/Auth/Login', data);
  const token = response.data; 

  console.log(token);
  localStorage.setItem("token", token);
  const decodedToken = jwtDecode(token);
  localStorage.setItem("user", JSON.stringify(decodedToken));
  window.location.href = "/";
  setAuthToken(token);
  return response;
}

export const signUp = async(data)=>{
  const response = await _axios.post('/api/Auth/Regiter', data);
  return response;
}

export const roomSearch = async(data)=>{
  const response = await _axios.post('/api/Room/RoomSearch', data);
  return response;
}

export const GetAllRoomTypes = async()=>{
  const response = await _axios.get('/RoomTypes/GetAllRoomTypes');
  return response;
}
export const GetRoomByFilter = async(roomId, filterDate)=>{
  const {data} = await _axios.post(`/api/Room/GetRoomById/${roomId}`,filterDate);
  return data;
}

export const postReview = async(reviewData)=>{
  const token = localStorage.getItem('token')
  setAuthToken(token);
  const {data} = await _axios.post(`/api/Review/AddReview`,reviewData);
  return data;
}

//confirm email
export const ConfirmEmail = async (code) => {
  console.log(code);
  const { data } = await _axios.post(`/api/Auth/ConfirmAccount`,{code});
  return data;
};

// Get User profile
export const GetUser = async () => {
  const token = localStorage.getItem('token');
  const user =JSON.parse(localStorage.getItem('user'))
  setAuthToken(token, _axios); 
  const response = await _axios.get(`/api/Auth/GetUser/${user.id}`);
  return response.data;
};
 
export const UploadProfileImage = async (image) => {
  const token = localStorage.getItem('token');
  setAuthToken(token, _axios); 
  const response = await _axios.post(`/api/Auth/ProfileImage`,image, {
    headers: {
      "Content-Type": "multipart/form-data",
      accept: "*/*",
    },
  });
  return response.data;
};

export const ChangePassword = async (data) => {
  const token = localStorage.getItem('token');
  setAuthToken(token, _axios); 
  const response = await _axios.post(`/api/Auth/ResetPassword`,data);
  return response.data;
};

export const UpdateUser = async (data) => {
  const token = localStorage.getItem('token');
  setAuthToken(token, _axios); 
  const response = await _axios.put(`/api/Auth/ChangeUserData`,data);
  return response.data;
};

//forget-password
export const ForgetPass = async (email) => {
  const token = localStorage.getItem('token');
  setAuthToken(token,_axios);
  const { data } = await _axios.post(`/api/Auth/ForgetPassword?email=${email}`);
  return data;
};

//RoomView
export const RoomView = async (id) => {
  const token = localStorage.getItem('token');
  setAuthToken(token,_axios);
  const { data } = await _axios.post(`/api/Room/RoomView?id=${id}`);
  return data;
};
//GetRecentlyRoom
export const getRecentlyRoom = async (roomData) => {
  const token = localStorage.getItem('token');
  setAuthToken(token,_axios);
  const { data } = await _axios.post(`/api/Room/GetRecentlyRoom`,roomData);
  return data;
};