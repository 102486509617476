import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaTiktok, FaEnvelope} from "react-icons/fa"; 

import logo from "../../assets/Login/Regency Plaza Hotels 2.png";
import LanguageDropdown from "./languages";
import avatar from "../../assets/Main/avatar.png";
export default function Navbar() {
  const user = localStorage.getItem("user");
  const navigate = useNavigate();
  const handleLogOut = () => {
    localStorage.removeItem('user')
    navigate("/login");
  };
  return (
    <>
    <div className="position-fixed z-index mt-2 top-15 left-2 py-0 w-95">
    <div className="row justify-content-end pe-3 pb-2">
                {/* Social Media Links */}
                <div className=" d-flex justify-content-end align-items-center ">
                  <div className="rounded-circle border nav-icon">
                    <Link
                      to="https://www.facebook.com/RegencyPlazaHotels"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-primary-me"
                    >
                      <FaFacebookF size={15} />
                    </Link>
                  </div>
                  <div className="rounded-circle border nav-icon mx-1">
                    <Link
                      to="https://www.instagram.com/regencyplazasharm/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-danger "
                    >
                      <FaInstagram size={15} />
                    </Link>
                  </div>
                  <div className="rounded-circle border nav-icon me-1">
                    <Link
                      to="https://www.tiktok.com/@regencyplazasharm?lang=en"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark"
                    >
                      <FaTiktok size={15} />
                    </Link>
                  </div>
                  <div className="rounded-circle border nav-icon">
                    <Link
                      to="mailto:reservation@regencyplazasharm.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark"
                    >
                      <FaEnvelope size={15} />
                    </Link>
                  </div>
                </div>
              </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light border border-info rounded-5 py-0">
        <div className="container-fluid">
          <Link className="navbar-brand ps-lg-2 d-none d-md-block" to="/">
            <img className="" src={logo} alt="regency logo" />
          </Link>
          {user?
          <ul className="navbar-nav w-25 d-block d-md-none">
                    <li className="nav-item dropdown me-3">
                    <a
                      className="nav-link dropdown-toggle no-caret "
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                  
                    src={avatar}
                    alt="avatar"
                    className=" rounded-5 avatar-hover w-50"
                  />
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                          }
                          to="/account"
                        >
                          Profile
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                          }
                          to="/romance-regency-club/3"
                          onClick={() => handleLogOut()}
                        >
                          Log out
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                     </ul>:<Link className="navbar-brand ps-lg-2 d-md-none" to="/">
            <img className="d-block d-md-none" src={logo} alt="regency logo" />
          </Link>
}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse row justify-content-around "
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav col-12 col-lg-8 ms-3 ms-lg-0 fw-bold-me text-me ">
              <li className="nav-item me-3 ">
                <NavLink
                  className="nav-link"
                  to="/"
                  activeclassname="active"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Explore
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/plaza-aqua-park/1"
                    >
                      Regency Plaza Aqua Park
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/royal-regency-hotel/2"
                    >
                      Royal Regency Club
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/romance-regency-club/3"
                    >
                      Romance Regency Club
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="Rooms"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Rooms
                </a>
                <ul className="dropdown-menu" aria-labelledby="Rooms">
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/hotel/1/rooms"
                    >
                      Regency Rooms
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/hotel/2/rooms"
                    >
                      Royal Rooms
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/hotel/3/rooms"
                    >
                      Romance Rooms
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/* Beach */}
              <li className="nav-item dropdown me-3">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="Beach"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Beach
                </a>
                <ul className="dropdown-menu" aria-labelledby="Beach">
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/regency-facilities/1/4"
                    >
                      Regency Beach
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/regency-facilities/2/4"
                    >
                      Royal Beach
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                      }
                      to="/regency-facilities/3/4"
                    >
                      Romance Beach
                    </NavLink>
                  </li>
                </ul>
              </li>
              {/*Aqua park */}
              <li className="nav-item me-3">
                <NavLink
                  className="nav-link"
                  to="/swimming-pools/4"
                  activeclassname="active"
                >
                  Aqua park
                </NavLink>
              </li>
              <li className="nav-item me-3">
                <NavLink
                  className="nav-link"
                  to="/about-us"
                  activeclassname="active"
                >
                  About us
                </NavLink>
              </li>
              <li className="nav-item me-3">
                <NavLink className="nav-link" to="contact">
                  Contact
                </NavLink>
              </li>
            </ul>
            <div className="col-12 col-lg-4">
              <div className="pb-3 pe-3 pb-lg-0 d-block d-lg-flex justify-content-end align-items-center  ">
                <div className="pe-3">
                  <LanguageDropdown />
                </div>
                {user ? (
                     <ul className="navbar-nav w-25 d-none d-md-block">
                    <li className="nav-item dropdown me-3">
                    <a
                      className="nav-link dropdown-toggle no-caret "
                      href="#"
                      id="navbarDropdownMenuLink"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                  
                    src={avatar}
                    alt="avatar"
                    className=" rounded-5 avatar-hover w-50"
                  />
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdownMenuLink"
                    >
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                          }
                          to="/account"
                        >
                          Profile
                        </NavLink>
                      </li>
                      <li className="bg-transparent"  onClick={() => handleLogOut()}>
                        <NavLink
                          className={({ isActive }) =>
                            `dropdown-item ${isActive ? "bg-primary-color2" : ""}`
                          }
                          // to="/login"
                          onClick={() => handleLogOut()}
                        >
                          Log out
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                     </ul>
                 
                ) : (
                  <div className="text-me">
                    <NavLink
                      to="/signup"
                      className="btn bgPrimaryColor text-white rounded-5 py-1 px-2 text-sm me-2 custom-btn text-me"
                    >
                      Sign up
                    </NavLink>

                    <NavLink
                      to="/login"
                      className="btn border-primary-me text-primary-me  rounded-5 py-1 px-2 text-sm text-me custom-btn"
                    >
                      Log In
                    </NavLink>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

    </div>
    </>
  );
}
