import React from "react";
import Slider from "react-slick";
import img1 from "../../assets/homeSlider/step=1.png";
import img2 from "../../assets/homeSlider/step=2.png";
import img3 from "../../assets/homeSlider/step=3.png";
import img4 from "../../assets/homeSlider/step=4.png";
import img5 from "../../assets/homeSlider/step=5.png";
import './home-slider.css';

function MobileSlider() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, 
    autoplaySpeed: 3000, 
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div className="slide">
          <div className="slide-image-container ">
            <img src={img1} alt="Step 1" />
            <div className="slide-text rounded">
              {/* <h3>Step 1</h3> */}
              <p>Enter the realm, luxuriate in opulence, depart</p>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="slide-image-container">
            <img src={img2} alt="Step 2" />
            <div className="slide-text rounded">
              {/* <h3>Take a brief tour through our hotel's highlights</h3> */}
              <p>Take a brief tour through our hotel's highlights</p>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="slide-image-container">
            <img src={img3} alt="Step 3" />
            <div className="slide-text rounded">
              {/* <h3>Step 3</h3> */}
              <p>Unlock luxury, discover comfort, indulge endlessly</p>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="slide-image-container">
            <img src={img4} alt="Step 4" />
            <div className="slide-text rounded">
              {/* <h3>Step 4</h3> */}
              <p> Unveil opulence, embrace comfort, immerse in luxury</p>
            </div>
          </div>
        </div>
        <div className="slide">
          <div className="slide-image-container">
            <img src={img5} alt="Step 5" />
            <div className="slide-text rounded">
              {/* <h3>Step 5</h3> */}
              <p>Embrace elegance, ignite adventure, relish serenity, savor</p>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default MobileSlider;
