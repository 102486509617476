import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import view3 from "../../../assets/recentely view/image 36.png";
import check from "../../../assets/recentely view/check 4.png";
import { Link, useParams } from "react-router-dom";
import { useGetRecentlyRoom } from "../../../services/queries";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../services/api";
import "../allRooms/AllRooms.css"; // Add this for custom styles

// Custom arrow components
const NextArrow = ({ onClick }) => (
  <div className="custom-arrow next-arrow" onClick={onClick}>
    <i className="fa fa-chevron-right"></i>
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="custom-arrow prev-arrow" onClick={onClick}>
    <i className="fa fa-chevron-left"></i>
  </div>
);

export const RecentlyViewed = () => {
  const {hotelId} = useParams()
  const {i18n} = useTranslation()
  const[roomsIdArr,setRoomsId]=useState([])
  useEffect(()=>{
    const roomsId =JSON.parse(localStorage.getItem('roomsId'))
    if (roomsId) {
      console.log(roomsId)
      setRoomsId(roomsId)
    }
  },[])
  const {data:recentlyRoomsData,error} = useGetRecentlyRoom({roomsId:roomsIdArr,language:i18n.language})
if (recentlyRoomsData) {
  console.log("recentlyRoomsData", recentlyRoomsData);
}
if (error) {
  console.log("error", error);
}

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  autoplay: true, 
  autoplaySpeed: 3000,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

return (
  <section className="container my-5">
    <div className="py-3">
      <h2>Recently View</h2>
    </div>
    <div className="slider-container m-auto">
      <Slider {...settings}>
      {recentlyRoomsData?.map((room) => (
          <div className="col-md-4" key={room.id}>
            <div className="rounded-4 m-2 cursor-pointer border  cardPosition">
            <img
  src={`${room.images[0] ? BASE_URL + room.images[0] : view3}`}
  className="card-img-top rounded-top-4"
  alt={room.name}
  style={{ width: "100%", height: "250px", objectFit: "cover" }}
/>
              <div className="fav"></div>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="card-title">{room.name}</h5>
                    <span className="text-muted">{room.nights} Nights</span>
                  </div>

                  <div>
                    <div className="d-flex justify-content-center align-items-center">
                      <h4 className="ms-2 mb-0 me-2">
                        {room.child + room.adult}
                      </h4>
                      <i className="fa-solid fa-user-group fs-5"></i>
                    </div>
                    <div className="d-flex">
                      <span className="text-muted">{room.adult} adult</span>
                      <span className="text-muted">, {room.child} child</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center py-3 text-muted px-3">
                  {room?.features?.map((feature) => (
                    <div className="item">
                      <div className="d-flex justify-content-center">
                        <img src={BASE_URL + feature.image} alt="person" 
                            style={{ width: "30", height: "30px", objectFit: "cover" }}
                        />
                      </div>
                      <span>{feature.name}</span>
                    </div>
                  ))}
                </div>
                <div>
                  <div className="d-flex">
                    <div className="pe-3">
                      <img src={check} alt="check" />
                    </div>
                    <p className="mb-0">High Speed Wifi</p>
                  </div>
                  <div className="d-flex">
                    <div className="pe-3">
                      <img src={check} alt="check" />
                    </div>
                    <p className="mb-0">Shower</p>
                  </div>
                  <div className="d-flex">
                    <div className="pe-3">
                      <img src={check} alt="check" />
                    </div>
                    <p className="mb-0">Air-Conditioning</p>
                  </div>
                  <div className="d-flex">
                    <div className="pe-3">
                      <img src={check} alt="check" />
                    </div>
                    <p className="mb-0">Parking</p>
                  </div>
                </div>
                <div className="d-flex pt-4">
                  <h3 className="pe-2">₹{room.total}</h3>
                  <span className="text-muted pt-2">Total</span>
                </div>
                <div className="py-2 text-end">
        <Link
              to={`/book-room/${hotelId}`}
              className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn block"
            >
              Book Now
            </Link>
        </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  </section>
);
};
