import { _axios } from "./api";

export const GetServices = async(lang)=>{
   const {data} = await _axios.get(`/api/Services/GetServices/${lang}`);
   return data;
 }

 export const GetHomeFacilities = async(lang)=>{
   const {data} = await _axios.get(`/api/Facilities/GetHomeFacility/${lang}`);
   return data;
 }

 export const GetLuxuriousRoom = async(lang)=>{
   const {data} = await _axios.get(`/api/Room/GetLuxurioustRoom/${lang}`);
   return data;
 }
 export const GetHotelReviews = async(hotelId)=>{
  const {data} = await _axios.get(`/api/Review/GetHotelReviews/${hotelId}`);
  return data;
}