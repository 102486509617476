import React, { useEffect } from "react";
// import imagebg from "../../assets/swimming pools/image_about-bg (1).png";
import SwimmingPool1 from "../../assets/swimming pools/6 Swimming Pools/Children_s Club Pool/Kids area beside kids club with swimimg pool.JPG";
import SwimmingPool2 from "../../assets/swimming pools/6 Swimming Pools/Children_s Pool - Main Pool Area/Swimming Pool9.jpg";
import SwimmingPool3 from "../../assets/swimming pools/6 Swimming Pools/Children_s Pool at the Water Park/Aquapark - .jpg";
import SwimmingPool4 from "../../assets/swimming pools/6 Swimming Pools/Main Pool/Swimming Pool1 - Copy - Copy.JPG";
import SwimmingPool5 from "../../assets/swimming pools/6 Swimming Pools/Main Pool at the Water Park/Aquapark overview 5.jpg";
import SwimmingPool6 from "../../assets/swimming pools/6 Swimming Pools/Relaxing Pool/sunset pool.JPG";
import romancePool7 from "../../assets/swimming pools/romance/Swimming pool.jpg";
import romancePool8 from "../../assets/swimming pools/romance/DSC_0050.JPG";


import "./facilites.css";
import { Link, useParams } from "react-router-dom";

const images = [
  {
    image: SwimmingPool5,
    id: 5,
    header: "Main Pool at the Water Park",
    description: "This is the description for the fifth image.",
  },
  {
    image: SwimmingPool3,
    id: 3,
    header: "Children_s Pool at the Water Park",
    description: "This is the description for the third image.",
  },
  {
    image: SwimmingPool1,
    id: 1,
    header: "Children_s Club Pool",
    description: "This is the description for the first image.",
  },
  {
    image: SwimmingPool2,
    id: 2,
    header: "Children_s Pool - Main Pool Area",
    description: "This is the description for the second image.",
  },
 
  {
    image: SwimmingPool4,
    id: 4,
    header: "Main Pool",
    description: "This is the description for the fourth image.",
  },
  {
    image: SwimmingPool6,
    id: 6,
    header: "Relaxing Pool",
    description: "This is the description for the sixth image.",
  },
  {
    image: romancePool7,
    id: 7,
    header: "Romance Private Pool",
    description: "This is the description for the seventh image.",
  },
  {
    image: romancePool8,
    id: 8,
    header: "Romance Private Pool2",
    description: "This is the description for the eighth image.",
  }
];


const HotelPools = () => {
  const { hotelId } = useParams();
  const regency = "Regency Plaza Aqua Park";
  const royal = "Royal Regency Club";
  const romance = "Romance Regency Club";
  const AquaPark = "Aqua Park";
  useEffect(()=>{
    if (hotelId==1) {
       document.title = regency + ' - Pools';
    }
    if (hotelId==2) {
      document.title = royal + ' - Pools';
   }
   if (hotelId==3) {
    document.title = romance + ' - Pools';
 }
 if (hotelId==4) {
  document.title = AquaPark + ' - Regency Plaza Aqua Park';
}
 },[regency,royal,romance,AquaPark]);

  const filteredImages = images.filter((item) => {
    if (hotelId === "1" || hotelId === "2") {
      return item.id !== 7 && item.id !== 8;
    }
    if (hotelId === "4" ) {
      return item.id == 3 || item.id == 5;
    }
    return true;
  });
  return (
    <>
      <div className="overlay-container">
        {/* Overlay Content */}
        <div className="">
          <div className=" backgroundPool d-flex justify-content-center align-items-center">
          <div >
            <div className="layer2 p-5 rounded-5 container fade-in-animation text-center pt-5 mt-5 pt-md-0 mt-md-0">
            {hotelId == 1 && <h1 className="display-4 fw-bold">{regency}</h1>}
              {hotelId == 2 && <h1 className="display-4 fw-bold">{royal}</h1>}
              {hotelId == 3 && <h1 className="display-4 fw-bold">{romance}</h1>}
              {hotelId == 4 && <h1 className="display-4 fw-bold">{AquaPark}</h1>}
              <p className="fs-5">
  Dive into endless fun at our Aqua Park, featuring thrilling water slides, wave pools, and splash zones for guests of all ages. Whether you're seeking excitement or relaxation, our Aqua Park offers the perfect blend of adventure and leisure, making it an ideal destination for families and friends.
</p>
              <Link
                to={`/book-room/${hotelId}`}
                className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn"
              >
                Book Now
              </Link>

            </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-5">
        <div className="row">
          {filteredImages.map((item, index) => {
            const { image, header, description,id } = item;
            if (index % 4 === 0 || index % 4 === 3) {
              // Big image column
              return (
                <div  key={index} id={id} className="col-md-8 mb-4 d-flex flex-column">
                  <Link to={`/pool-details/${id}/${hotelId}`}  className="img-container big-image position-relative">
                    <img
                      src={image}
                      alt={`img-${index}`}
                      className="img-fluid rounded"
                    />
                    <div className="overlay text-center text-white">
                      <h3>{header}</h3>
                      <p>{description}</p>
                    </div>
                  </Link>
                </div>
              );
            } else {
              // Small image column
              return (
                <div key={index} id={id} className="col-md-4 mb-4 d-flex flex-column">
                  <Link to={`/pool-details/${id}/${hotelId}`} className="img-container small-image position-relative">
                    <img
                      src={image}
                      alt={`img-${index}`}
                      className="img-fluid rounded"
                    />
                    <div className="overlay text-center text-white">
                      <h3>{header}</h3>
                      <p>{description}</p>
                    </div>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};

export default HotelPools;
