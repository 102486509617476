import React, { useState, useEffect } from "react";
import avatar from "../../assets/Main/avatar.png";
import { useTranslation } from 'react-i18next';
import LoadingPage from "../loading/loadingPage";
import { useChangePassword, useUpdateUser, useUploadProfileImage } from "../../services/mutations";
import { useGetUser } from "../../services/queries";
import { BASE_URL } from "../../services/api";
import './account.css'
const Account = () => {
  const { t, i18n } = useTranslation();
  const { data: user, refetch } = useGetUser();
if (user) {
    console.log(user);
    
}
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    userName: "",
    phone: ""
  });

  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newePassword: "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [profileMessage, setProfileMessage] = useState("");
  const [imageMessage, setImageMessage] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [isPasswordChangeSuccess, setIsPasswordChangeSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false); 
  const [showNewPassword, setShowNewPassword] = useState(false); 

  const defaultImage = avatar;
  const uploadImage = useUploadProfileImage();
  const updateUser = useUpdateUser();
  const changePassword = useChangePassword();

  useEffect(() => {
    if (user) {
      const [firstName, lastName] = user.fullName.split(" ");
      setFormData({
        firstName: firstName || "",
        lastName: lastName || "",
        email: user.email,
        userName: user.userName,
        phone: user.phoneNumber
          });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    setProfileMessage("");
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevData) => ({ ...prevData, [name]: value }));
    setPasswordMessage("");
    setIsPasswordChangeSuccess(null);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      const imageFormData = new FormData();
      imageFormData.append("image", e.target.files[0]);
      setSelectedImage(imageFormData);

      setIsLoading(true);
      uploadImage.mutate(imageFormData, {
        onSuccess: () => {
          refetch();
          setImageMessage(t("imgMsg"));
          setIsLoading(false);
        },
        onError: () => {
          setImageMessage(t("errImgMsg"));
          setIsLoading(false);
        },
      });
    }
  };

  const handleSaveChanges = () => {
    setIsLoading(true);
    console.log(formData);

    updateUser.mutate(formData, {
      onSuccess: () => {
        refetch();
        setProfileMessage(t("profileMsg"));
        setIsLoading(false);
      },
      onError: () => {
        setProfileMessage(t("errProfileMsg"));
        setIsLoading(false);
      },
    });
  };

  const handleChangePassword = () => {
    setIsLoading(true);
    changePassword.mutate(passwordData, {
      onSuccess: () => {
        setPasswordMessage(t("passMsg"));
        setIsPasswordChangeSuccess(true);
        setIsLoading(false);
      },
      onError: (err) => {
        if (err.response.data[0]=='PasswordMismatch') {
          setPasswordMessage(t("PasswordMismatch"));
        }
        setIsPasswordChangeSuccess(false);
        setIsLoading(false);
      },
    });
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">{t("accountSettings")}</h2>

      {profileMessage && <div className="alert alert-success">{profileMessage}</div>}
      {imageMessage && <div className="alert alert-success">{imageMessage}</div>}
      {isLoading && <div className="alert alert-info"><LoadingPage/></div>}

      <div className="row ">
        {/* Profile Image Section */}
        <div className="col-md-4 text-center">
          <div className="profile-image-wrapper position-relative">
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage.get("image"))
                  : user?.profileImage
                  ? `${BASE_URL}${user.profileImage}`
                  : defaultImage
              }
              alt="Profile"
              className="rounded-circle img-thumbnail profile-image"
              style={{ width: "150px", height: "150px" }}

            />
            <input
              type="file"
              onChange={handleImageChange}
              className="form-control mt-2"
            />
            <small className="text-muted d-block mt-2">{t("imageInfo")}</small>
          </div>
        </div>

        {/* Form Section */}
        <div className="col-md-8">
          <form>
            {["firstName", "lastName", "userName", "email", "phone"].map(
              (field, index) => (
                <div className="mb-3 row align-items-center" key={index}>
                  <label className="col-sm-3 col-form-label text-end">
                    {t(field)}
                  </label>
                  <div className="col-sm-9">
                  <input
                        type="text"
                        name={field}
                        placeholder={t(`${field} Placeholder`)}
                        value={formData[field]}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                  </div>
                </div>
              )
            )}
            <div className="ms-2 col-5 d-flex justify-content-end">
              <button
                type="button"
                onClick={handleSaveChanges}
                className="btn bgPrimaryColor text-white "
                disabled={isLoading}
              >
                {t("saveChanges")}
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Change Password Section */}
      <h2 className="mt-5 mb-3 text-start">
        {t("changePassword.changePasswordTitle")}
      </h2>
      <form>
      {["oldPassword", "newePassword"].map((field, index) => (
  <div className="mb-3 position-relative" key={index}>
    <input
      type={
        field === "oldPassword"
          ? showOldPassword
            ? "text"
            : "password"
          : showNewPassword
          ? "text"
          : "password"
      }
      name={field}
      placeholder={t(`${field}PlaceHolder`)}
      value={passwordData[field]}
      onChange={handlePasswordChange}
      className="form-control"
    />
    <button
      type="button"
      onClick={() =>
        field === "oldPassword"
          ? setShowOldPassword((prev) => !prev)
          : setShowNewPassword((prev) => !prev)
      }
      className="btn btn-link position-absolute end-0 top-50 translate-middle-y text-decoration-none"
    >
      {field === "oldPassword"
        ? showOldPassword
          ? t("hide")
          : t("show")
        : showNewPassword
        ? t("hide")
        : t("show")}
    </button>
  </div>
))}

        {passwordMessage && (
          <div
            className={`text-${isPasswordChangeSuccess ? "success" : "danger"} small`}
          >
            {passwordMessage}
          </div>
        )}
        <button
          type="button"
          onClick={handleChangePassword}
          className="btn bgPrimaryColor text-white"
          disabled={isLoading}
        >
          {t("changePasswordButton")}
        </button>
      </form>
    </div>
  );
};

export default Account;
