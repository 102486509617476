import React from 'react';
import 'leaflet/dist/leaflet.css';

const HotelMap = () => {
  return (
    <div className='pb-5'>
      <h2 className='ps-5'>Find Us</h2>

      <div className="col-md-12 wow fadeIn" data-wow-delay="0.1s">
      <iframe
      title='Regency Plaza Aqua park'
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.906317228816!2d34.432211475050536!3d28.05300961016204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14534dc5c9318ad9%3A0xe43f93a89b4edf54!2sRegency%20Plaza%20Aqua%20Park%20%26%20SPA!5e1!3m2!1sen!2seg!4v1734263095904!5m2!1sen!2seg"
      width="800"
      height="600"
      style={{
        border: 0,
        width: '100%', // Example of responsive width
        height: '400px' // Example height adjustment
      }}
      allowFullScreen
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
        </div>
    </div>
  );
};

export default HotelMap;
