import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import "@fortawesome/fontawesome-free/css/all.css";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 

import './App.css';
import './index.css'; 

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import SignIn from './components/auth/signin/signin';
import SignUp from './components/auth/signup/signup';

import { Layout } from './components/layout/layout';
import Home from './components/home/home';
import { RoyalRegency } from './components/hotels/royalRegency/royalRegency';
import { PlazaAquaPark } from './components/hotels/plazaAquapark/plazaAquepark';
import { RomanceRegencyClub } from './components/hotels/romanceRegencyClub/romanceRegencyClub';
import About from './components/about/about';
import AccountFlow from './components/accountFlow/AccountFlow';
import BookRoom from './booking/bookRoom';
import DateRange from './booking/dateRange';
import MainRestaurants from './components/facilites/resturents/main';
import SingleRestaurant from './components/facilites/resturents/singleResturent';
import Facilities from './components/facilites/Facilities';
import { AllRooms } from './components/facilites/rooms';
import HotelPools from './components/facilites/hotelPools';
import { PoolDetails } from './components/facilites/poolDetails';
import { RoomDetails } from './components/roomDetails/roomDetails';
import Contact from './components/contact/contact';
import RoomPicsSlider from './components/roomDetails/roomPicsSlider';
import SliderTest from './components/home/homeSliderTest/homeSliderTest';
import ConfirmEmail from './components/auth/signup/Confirm';
import ForgetPass from './components/auth/forgetPass/forget-password';
import MobileSlider from './components/homeSlider/mobileSlider';

let routers = createBrowserRouter([
  {
    path: "", element: <Layout />, children: [
      { index: true, element: < Home/> },
      { path: "home", element: <Home /> },
      { path: "login", element: <SignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "forget-password", element: <ForgetPass /> },
      { path: "royal-regency-hotel/:hotelId", element: <RoyalRegency /> },
      { path: "plaza-aqua-park/:hotelId", element: <PlazaAquaPark /> },
      { path: "romance-regency-club/:hotelId", element: <RomanceRegencyClub /> },
      { path: "about-us", element: <About /> },
      { path: "contact", element: <Contact /> },
      { path: "account", element: <AccountFlow /> },
      { path: "book-room/:hotelId", element: <BookRoom /> },
      { path: "main-restaurants/:hotelId", element: <MainRestaurants /> },
      { path: "single-restaurant/:restaurantId/:hotelId", element: <SingleRestaurant /> },
      { path: "regency-facilities/:hotelId/:categoryId", element: <Facilities /> },
      { path: "hotel/:hotelId/rooms", element: <AllRooms /> },
      { path: "swimming-pools/:hotelId", element: <HotelPools /> },
      { path: "pool-details/:id/:hotelId", element: <PoolDetails /> },
      { path: "RoomDetails", element: <RoomDetails /> },
      { path: "Test", element: <SliderTest /> },
      { path: "MobileSlider", element: <MobileSlider /> },
      {path: "/:code",element: <ConfirmEmail />}
    ],
  },
]);


function App() {
  return <>
    <RouterProvider router={routers}></RouterProvider>
    </>
}

export default App;
