import React, { useEffect, useState } from "react";
import accountStyle from "./account.module.css";
import img from "../../assets/account/Image Placeholder.png";
import upload from "../../assets/account/Vector (14).png";
import edit from "../../assets/account/Frame 241.png";
import Account from "./account";
import { Link } from "react-router-dom";
import BookRoom from "../../booking/bookRoom";
import "./account.css";
import BookingHistory from "./bookingHistory";
import { useGetUser } from "../../services/queries";
import avatar from "../../assets/Main/avatar.png";
import { BASE_URL } from "../../services/api";

export default function AccountFlow() {
  const { data: user } = useGetUser();
  const defaultImage = avatar;

  const [openToggle, setOpenToggle] = useState(1);

  const handleToggle = (toggleId) => {
    setOpenToggle((prev) => (prev === toggleId ? null : toggleId));
  };
  useEffect(() => {
    document.title = "Regency Plaza Aqua Park - Your Profile";
  }, []);
  return (
    <>
      <br /> <br />
      <section className="container pt-5">
        <div className={`${accountStyle.accountBg} position-relative my-5`}>
          <div className="position-absolute top-100 start-50 translate-middle pt-5 mt-3">
            <div className="position-relative">
              <div className="position-absolute start-50 top-100 translate-middle">
                <div className="position-relative ">
                  <img
                    src={
                      user?.profileImage
                        ? `${BASE_URL}${user.profileImage}`
                        : defaultImage
                    }
                    alt="Profile"
                    className="rounded-circle img-thumbnail profile-image border rounded-circle border-5 border-primary-me"
                    style={{ width: "150px", height: "150px" }}
                 />
                </div>
                <div className="text-center">
                  <h3 className="pt-3">{user?.userName}</h3>
                  <p className="text-muted">{user?.email}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* toggle */}
        <br />
        <br />
        <br />
        <div className="toggle mt-5 pt-5">
          <div className="row m-auto text-center">
            <div className="col-md-4 border-end">
              <Link
                className={`w-100 text-decoration-none toggle-link ${
                  openToggle === 1 ? "active" : ""
                }`}
                onClick={() => handleToggle(1)}
                aria-expanded={openToggle === 1}
              >
                Account
              </Link>
            </div>
            <div className="col-md-4 border-end">
              <Link
                className={`w-100 text-decoration-none toggle-link ${
                  openToggle === 2 ? "active" : ""
                }`}
                onClick={() => handleToggle(2)}
                aria-expanded={openToggle === 2}
              >
                Tickets/Bookings
              </Link>
            </div>
            <div className="col-md-4">
              <Link
                className={`w-100 text-decoration-none toggle-link ${
                  openToggle === 3 ? "active" : ""
                }`}
                onClick={() => handleToggle(3)}
                aria-expanded={openToggle === 3}
              >
                Booking History
              </Link>
            </div>
          </div>
          <div>
            <div className={`collapse ${openToggle === 1 ? "show" : ""}`}>
              <div className="card card-body my-5">
                <Account />
              </div>
            </div>
            <div className={`collapse ${openToggle === 2 ? "show" : ""}`}>
              <div className="card card-body my-5">
                {/* <BookRoom /> */}
                <h6>Coming Soon....</h6>

              </div>
            </div>
            <div className={`collapse ${openToggle === 3 ? "show" : ""}`}>
              <div className="card card-body my-5">
                <BookingHistory />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
