import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetHotelReviews } from "../../../services/queries";
import WriteReview from "./postReview";
import { BASE_URL } from "../../../services/api";
import avatar from "../../../assets/Main/avatar.png";

const HotelReviews = () => {
  const { hotelId } = useParams();
  const { data: hotelReviews } = useGetHotelReviews(hotelId);
  const [successMessage, setSuccessMessage] = useState("");
    const [toastVisible, setToastVisible] = useState(false);
    const user =JSON.parse(localStorage.getItem("user"))

  const reviews = [
    { rating: 5, title: "Excellent" },
    { rating: 4, title: "Very Good" },
    { rating: 3, title: "Good" },
    { rating: 2, title: "Fair" },
    { rating: 1, title: "Poor" },
  ];
  // Create a lookup table for the reviews array
  const reviewTitles = new Map(
    reviews.map((item) => [item.rating, item.title])
  );

  const [showModal, setShowModal] = useState(false);
const navigate = useNavigate()
  const handleClose = () => {
   setShowModal(false);
  // setSuccessMessage("");
 };  
 const handleShow = () => setShowModal(true);
const handleLogin=()=>{
  navigate('/login')
}
 const handleReviewSubmit = (reviewData) => {
   console.log("Review submitted:", reviewData);
   setToastVisible(true); 
   setTimeout(() => setToastVisible(false), 3000); 
   setSuccessMessage("Thank you! Your review has been submitted and is under review by the admin.");
 if (successMessage) {
   console.log(successMessage, 'successMessage');
 }
   handleClose(); 
 };


  return (
    <div className="container my-5">
      <h3 className="mb-4">Reviews</h3>
          {/* Toast Notification */}
          {toastVisible && (
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 1050 }}
        >
          <div
            className="toast show align-items-center text-white bg-success border-0"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">
                <i className="fas fa-check-circle me-2"></i>
                {successMessage}
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                aria-label="Close"
                onClick={() => setToastVisible(false)}
              ></button>
            </div>
          </div>
        </div>
      )}

<div className="row align-items-center">
  <div className="col-12 col-md-8 text-center text-md-start">
    <h1 className="display-4">4.2</h1>
    <p className="lead text-muted">Very good</p>
    <p>371 verified reviews</p>
  </div>
  <div className="col-12 col-md-4 text-center text-md-end mt-3 mt-md-0">
    <button
      onClick={user ? () => handleShow() : () => handleLogin()}
      className="btn bgPrimaryColor text-white rounded-5 py-2 px-3 text-sm me-2 custom-btn text-me"
    >
      Give your review
    </button>
  </div>
</div>

      <hr />
      <div className="reviews-list">
  {hotelReviews?.slice(-5).map((review, index) => (
    <div 
      className="row justify-content-center align-items-center mb-4 text-center text-md-start" 
      key={index}
    >
      <div className="col-4 col-md-1">
        <img
          src={review.imageUrl ? BASE_URL + review.imageUrl : avatar}
          alt={review.fullName}
          className="rounded-circle img-fluid"
          style={{ height: "80px", width: "80px", objectFit: "cover" }}
        />
      </div>
      <div className="col-12 col-md-10 mt-3 mt-md-0">
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <p className="mb-1">
            <strong>
              {review.rating.toFixed(1)}{" "}
              {reviewTitles.get(review.rating) || "Good"}
            </strong>{" "}
            | {review.fullName}
          </p>
        </div>
        <p className="text-muted mb-0">{review.comment}</p>
      </div>
    </div>
  ))}
</div>

      {/* <nav aria-label="Page navigation">
        <ul className="pagination justify-content-center">
          <li className="page-item disabled">
            <button className="page-link" aria-label="Previous">
              &laquo;
            </button>
          </li>
          <li className="page-item active">
            <button className="page-link">1</button>
          </li>
          <li className="page-item">
            <button className="page-link">2</button>
          </li>
          <li className="page-item">
            <button className="page-link">3</button>
          </li>
          <li className="page-item">
            <button className="page-link" aria-label="Next">
              &raquo;
            </button>
          </li>
        </ul>
      </nav> */}
      {/* Bootstrap Modal */}
      <div
        className={`modal ${showModal ? "show" : ""}`}
        tabIndex="-1"
        role="dialog"
        style={{ display: showModal ? "block" : "none" }}
      >
        <div className="modal-dialog p-top-9 " role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="modal-title">Write a Review</h5>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <WriteReview handleReviewSubmit={handleReviewSubmit} />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add background overlay for the modal */}
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default HotelReviews;
