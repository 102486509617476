import React, { useEffect } from "react";
import aboutUs from "../../assets/contact/bg style.png";
import aboutUs2 from "../../assets/contact/Ellipse 626.png";
import goals from "../../assets/contact/Group 194 (1).png";
import romantic from "../../assets/contact/Illustration.png";
import goals2 from "../../assets/contact/image 919.png";
import aboutStyle from "./about.module.css";
import ReviewsSlider from "../sections/reviewsSlider/reviewsSlider";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "About Us - Regency Plaza Aqua Park";
  }, []);

  return (
    <>
      {/* Banner Section */}
      <section className={`${aboutStyle.bannerBg}`}>
        <div
          className={`d-flex justify-content-center align-items-center ${aboutStyle.bannerBgColor}`}
        >
          <div className="text-center px-4 px-md-5">
            <div className="layer rounded-5 p-3 fade-in-animation">
              <h1 className="BigshotOneFont text-white fs-5 fs-md-7 pt-5 mt-5 pt-md-0 mt-md-0">
                {t("about_us_page.about_us")}
              </h1>
              <p className="text-white fs-6">
                Welcome to our hotel, a destination where luxury meets comfort.
                With our prime location, exceptional amenities, and unparalleled
                service, we strive to create memorable experiences for every
                guest. From elegant rooms and gourmet dining to relaxing spa
                treatments and thrilling recreational activities, our hotel is
                designed to cater to your every need. Whether you're here for
                business, leisure, or a family getaway, our dedicated team is
                committed to making your stay truly unforgettable.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* About Us Section */}
      <section className="container my-5 pb-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 px-4 px-md-5">
            <h6 className="textPrimaryColor">
              {t("about_us_page.about_us")}
            </h6>
            <h3>{t("about_us_page.about_us_subtitle")}</h3>
            <p>{t("about_us_page.about_us_description")}</p>
          </div>
          <div className="col-12 col-md-6 order-first order-md-3 text-center">
            <div className="position-relative">
              <img
                src={aboutUs}
                className="w-75 position-absolute"
                alt=""
              />
              <img
                src={aboutUs2}
                className="w-100 position-relative"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      {/* Wanderlust Section */}
      <section className={`${aboutStyle.wanderlustBg}`}>
        <div
          className={`d-flex justify-content-center align-items-center h-100 ${aboutStyle.overLay}`}
        >
          <div className="text-center pt-5">
            <h1 className="BigshotOneFont text-white fs-5 fs-md-7">
              {t("about_us_page.wanderlust")}
            </h1>
          </div>
        </div>
      </section>

      {/* Goals Section */}
      <section className="container my-5">
        <div className="row align-items-center justify-content-center position-relative">
          <div className="col-12 col-md-6 text-center">
            <img src={goals} className="w-100" alt="" />
          </div>
          <div className="col-12 col-md-6 position-relative px-4 px-md-5">
            <div className="position-absolute top-0 end-0 d-none d-md-block">
              <img src={goals2} className="w-100" alt="" />
            </div>
            <h2 className="BigshotOneFont fs-4 fs-md-3">
              {t("about_us_page.goals_title")}
            </h2>
            <p>{t("about_us_page.goals_description")}</p>
          </div>
        </div>
      </section>

      {/* Romantic Destinations Section */}
      <section className="container my-5">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 order-first order-md-3 text-center">
            <img src={romantic} className="w-100" alt="" />
          </div>
          <div className="col-12 col-md-6 px-4 px-md-5">
            <h2 className="BigshotOneFont h3">
              {t("about_us_page.romantic_destinations_title")}
            </h2>
            <p>{t("about_us_page.romantic_destinations_description")}</p>
          </div>
        </div>
      </section>

      {/* Reviews Slider */}
      <ReviewsSlider />
    </>
  );
}
