import React from 'react';
  import { useTranslation } from "react-i18next";
  import { useGetRoomFilter } from "../../services/queries";
  import BookingCard from "./bookingCard";
  import "./roomDetails.css";
  import { useEffect, useState } from "react";
  import { useSearchParams } from "react-router-dom";
  import { BASE_URL } from "../../services/api";
import defualtRoom from '../../assets/home/Rectangle 10.png'
import RoomPicsSlider from './roomPicsSlider';
import { useViewRoom } from '../../services/mutations';

  export const RoomDetails = ()=>{
    const viewRoomMutate = useViewRoom()
    const [searchParams] = useSearchParams();
    const roomName = searchParams.get("roomName");
    const roomId = searchParams.get("roomId");
    const adult = searchParams.get("adult");
    const child = searchParams.get("child");
    const start = searchParams.get("start");
    const end = searchParams.get("end");
    const {i18n} = useTranslation()
    const filterData = {
      "language":i18n.language,
      "adult": adult,
      "child": child,
      "startDate": start,
      "endDate" :end
    }
    const [toastVisible, setToastVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    useEffect(()=>{
      const numericRoomId = Number(roomId);
        const roomsId = JSON.parse(localStorage.getItem('roomsId')) || [];
        if (!roomsId.includes(numericRoomId)) {
          roomsId.push(numericRoomId);
          localStorage.setItem('roomsId', JSON.stringify(roomsId));
        }
        viewRoomMutate.mutate(numericRoomId,{
        onSuccess:(res)=>{
          console.log(res);
        }
      })
    },[roomId])
    const handleShowAllPhotos = () => {
      setShowModal(true); 
    };
  
    const handleCloseModal = () => {
      setShowModal(false); 
    };

    const {data:roomByFilter,error} = useGetRoomFilter(roomId,filterData)
  if (error) {
    console.log(error);
    
  }
    useEffect(()=>{
    console.log(roomByFilter,'roomByFilter');
  },[roomByFilter])
  const handleShare = () => {
    const currentUrl = window.location.href;
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        setToastVisible(true); 
        setTimeout(() => setToastVisible(false), 3000); 
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };
    return<>
    <div className="container">
      <div className="roomDetails d-flex justify-content-start align-items-end">
        <div>
    <div className="text-start ">
      <h1 className="fw-bold">{roomByFilter?.name}</h1>
      <p className="text-muted">{roomByFilter?.hotelName}</p>
    </div>
    <div className="d-flex justify-content-start align-items-center mb-4">
      <div className="d-flex align-items-center me-3">
        <i className="fas fa-star text-warning me-2"></i>
        <span className="fw-bold">4.8</span>
        <span className="text-muted ms-2">(256 reviews)</span>
      </div>
      <button
                    onClick={handleShare}

      className="btn btn-link text-decoration-none text-muted">
        <i className="fas fa-share-alt me-1"></i> Share
      </button>
       {/* Toast Notification */}
       {toastVisible && (
        <div
          className="position-fixed bottom-0 end-0 p-3"
          style={{ zIndex: 1050 }}
        >
          <div
            className="toast show align-items-center text-white bg-success border-0"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">
                <i className="fas fa-check-circle me-2"></i>
                Link copied to clipboard!
              </div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                aria-label="Close"
                onClick={() => setToastVisible(false)}
              ></button>
            </div>
          </div>
        </div>
      )}
    </div>
        </div>
      </div>
      <div className="row g-3">
    <div className="col-6 col-md-3">
      <div className="d-flex justify-content-center align-items-center h-50">
        <img 
          src={roomByFilter?.images[1]? BASE_URL + roomByFilter?.images[1]:defualtRoom} 
          alt="Room View 1" 
          className="rounded mb-3"
          style={{ width: "300px", height: "300px", objectFit: "cover" }}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <img 
          src={roomByFilter?.images[2]?BASE_URL + roomByFilter?.images[2]:defualtRoom} 
          alt="Room View 2" 
          className="rounded"
          style={{ width: "300px", height: "300px", objectFit: "cover" }} // Fixed dimensions
        />
      </div>
    </div>

    <div className="col-12 col-md-6 d-flex justify-content-center align-items-center px-0">
      <img 
        src={roomByFilter?.images[0]?BASE_URL + roomByFilter?.images[0]:defualtRoom} 
        alt="Main Room" 
        className="rounded w-100"
        style={{ height: "600px", objectFit: "cover" }} // Fixed height for main image
      />
    </div>

    <div className="col-6 col-md-3">
      <div className="d-flex justify-content-center align-items-center">
        <img 
          src={roomByFilter?.images[3]?BASE_URL + roomByFilter?.images[3]:defualtRoom} 
          alt="Room View 3" 
          className="rounded mb-3"
          style={{ width: "300px", height: "300px", objectFit: "cover" }} // Fixed dimensions
        />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <img 
          src={roomByFilter?.images[4]?BASE_URL + roomByFilter?.images[4]:defualtRoom} 
          alt="Room View 4" 
          className="rounded mb-3"
          style={{ width: "300px", height: "300px", objectFit: "cover" }} // Fixed dimensions
        />
      </div>
    </div>
  </div>


    <div className="text-center mt-2">
      <button
      onClick={handleShowAllPhotos}
      className="btn bgPrimaryColor custom-btn text-white rounded-pill px-4 py-2">
        <i className="fas fa-images me-2"></i> Show all photos
      </button>
    </div>
    {showModal && (
        <div
          className="modal fade show d-block z-999 overflow-hidden"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0,0,0,0.8)" }}
        >                
        {/* <RoomPicsSlider/> */}

          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title">{roomByFilter?.name} Photos</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                  aria-label="Close"
                ></button>
              </div>
              <RoomPicsSlider roomByFilter={roomByFilter}/> 
            </div>
          </div>
        </div>
      )}
    <div>
      <div className="row justify-content-start align-items-start my-5">
    <div className="col-12 col-md-6">
    <div className="border-bottom">
  <h3>Description</h3>
  <p>{roomByFilter?.description}</p>

    </div>
    <div className="mt-3">
      <h3>Amenities</h3>
      <div className="row justify-content-center align-items-center gy-3 p-2">
        {roomByFilter?.features?.map((feature)=>(
  <div className="col-4 d-flex justify-content-center align-items-center">
    <img className="me-2" src={BASE_URL+feature.image} alt="" srcset="" />
  <h6>{feature.name}</h6>
  </div>
        ))}
      </div>
    </div>
    </div>
    <div className="col-12 col-md-6  d-flex justify-content-center align-items-center">
  <BookingCard filterData={filterData} roomByFilter={roomByFilter} />
  </div>
      </div>

    </div>
  </div>

    </>
  }