import React, { useState } from 'react'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './roomPicsSlider.css';
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { BASE_URL } from '../../services/api';

export default function RoomPicsSlider({ roomByFilter }) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  if (!roomByFilter || !roomByFilter.images || roomByFilter.images.length === 0) {
    return <p>No images available</p>;
  }

  return (
    <>
      {/* Main Slider */}
      <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2 z-999"
      >
        {roomByFilter.images.map((image, index) => (
          <SwiperSlide key={index}>
            <img 
              src={BASE_URL+image} 
              alt={`Room image ${index + 1}`} 
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Thumbnails Slider */}
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {roomByFilter.images.map((image, index) => (
          <SwiperSlide key={index}>
            <img 
              src={BASE_URL+image} 
              alt={`Thumbnail image ${index + 1}`} 
              style={{ width: "100%", height: "auto", objectFit: "cover" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
