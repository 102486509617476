import React, { useEffect, useState } from "react";
import "./ToggleNav.css";
import "./mainResturant.css";
import { useGetRestaurants } from "../services/queries";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../services/api";

const RestaurantMenu = () => {
  const { hotelId } = useParams();
  const { i18n } = useTranslation();
  const [filteredResturant, setFilteredResturant] = useState([]);
  const [activeTab, setActiveTab] = useState("All Restaurants");

  const tabs = [
    { id: 0, tab: "All Restaurants" },
    { id: 1, tab: "Main Restaurant" },
    { id: 2, tab: "A La Carte Restaurant" },
    { id: 3, tab: "Snacks" },
    { id: 4, tab: "Bars" },
  ];

  const { data: restaurants, error } = useGetRestaurants({
    hotelId,
    lang: i18n.language,
  });

  const restaurantTitles = {
    1: "Regency Plaza Aqua Park Restaurants",
    2: "Royal Regency Club Restaurants",
    3: "Romance Regency Club Restaurants",
  };

  useEffect(() => {
    if (hotelId) document.title = restaurantTitles[hotelId];
  }, [hotelId]);

  useEffect(() => {
    setFilteredResturant(restaurants);
  }, [restaurants]);

  if (error) {
    console.error(error);
  }

  const fiteredData = (tab) => {
    setActiveTab(tab.tab);
    if (tab.id === 0) {
      setFilteredResturant(restaurants);
    } else {
      setFilteredResturant(
        restaurants.filter((res) => res.categoryId === tab.id)
      );
    }
  };

  const renderItems = () => {
    return filteredResturant?.map((item) => (
      <div key={item.id} className="p-2 col-12 col-sm-6 col-md-4 col-lg-3">
        <Link
          to={`/single-restaurant/${item.id}/${hotelId}`}
          className="rounded text-decoration-none"
        >
          <img
            src={`${BASE_URL}${item.imageUrl}`}
            className="w-100 rounded"
            alt={item.name}
          />
          <div className="p-3 text-center">
            <h5 className="text-lg font-semibold mb-1 text-black">{item.name}</h5>
          </div>
        </Link>
      </div>
    ));
  };

  return (
    <div className="pt-4">
      {/* Header Section */}
      <div className="hero d-flex justify-content-center align-items-center text-white text-center">
        <div className="fade-in-animation">
          <h1 className="fw-bold">{restaurantTitles[hotelId]}</h1>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="custom-toggle-nav py-4 d-flex flex-wrap justify-content-center align-items-center">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            id={tab.id}
            className={`nav-link m-2 ${activeTab === tab.tab ? "active" : ""}`}
            onClick={() => fiteredData(tab)}
          >
            {tab.tab}
          </button>
        ))}
      </div>

      {/* Items Grid */}
      <div className="container">
        <div className="row">{renderItems()}</div>
      </div>
    </div>
  );
};

export default RestaurantMenu;
