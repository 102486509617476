import React from 'react';

import {Outlet}  from "react-router-dom"
import Navbar from "../navbar/navbar"
import { Footer } from "../footer/footer"
import ScrollToTop from './scrollToTop';

export const Layout = ()=>{
return <> 
 <ScrollToTop />
 <Navbar/>
<Outlet></Outlet>  
<Footer/>
</>
}