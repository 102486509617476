import React, { useState } from "react";
import { useFormik } from "formik";
import SignUpSlider from "./signupSlider";
import { Link } from "react-router-dom";
import { useSignUp } from "../../../services/mutations";
import * as Yup from "yup";
import logo from "../../../assets/Login/Regency Plaza Hotels 2.png";
import { ClipLoader } from "react-spinners";
import './signup.css'
export default function SignUp() {
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const signUpMutation = useSignUp();

  const handleSignUpSubmit = (data) => {
    setLoading(true);
    signUpMutation.mutate(data, {
      onSuccess: (res) => {
        console.log(res);
        if (res.data == 'User created') {
          setConfirmMessage('Account created successfully please check your email to confirm your account!')
          setShowModal(true);
       console.log(confirmMessage);
        }
        setErrorMessage(null);
        setLoading(false);
      },
      onError: (error) => {
        setErrorMessage(error.response.data);
        console.log(errorMessage, "errorrrrrr");
        setLoading(false);
      },
    });
    console.log(data);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    phone: Yup.string().required("Phone is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*]).{8,}$/,
        "Password must be at least 8 characters long and contain one uppercase letter, one lowercase letter, and one special character."
      )
      .required("Password is required"),
    cPass: Yup.string()
      .required("Confirmation password is required.")
      .oneOf([Yup.ref("password"), null], "Passwords do not match."),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      role: "User",
    },
    validationSchema,
    onSubmit: handleSignUpSubmit,
  });

  return (
    <div className="container pt-5 ">
       {confirmMessage && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title">Confirmation</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setConfirmMessage("")}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <p>{confirmMessage}</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setConfirmMessage("")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row p-5 mt-5 ">
        <div className="col-md-1"></div>
        <div className="col-md-5 px-3 d-flex justify-content-around align-items-between ">
          <div>
            <div className="pb-3">
              <img src={logo} alt="regency logo" />
              <h1>Sign up</h1>
              <p>
                Let’s get you all set up so you can access your personal account.
              </p>
              {errorMessage ? (
                <div className="alert alert-danger">{errorMessage}</div>
              ) : null}
            </div>
            <form onSubmit={formik.handleSubmit} className="auth-box">
            <div className="d-flex justify-content-between">
               <div className=" user-box col-md-6 pe-2">
                  <input name="firstName" value={formik.values.firstName} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id="firstName"/>
                  <label htmlFor="firstName">First Name</label>
                  {formik.touched.firstName && formik.errors.firstName ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.firstName}
                             </div>
                               ) : null}
                </div>
                <div className=" user-box col-md-6">
                  <input name="lastName" value={formik.values.lastName} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id="lastName"/>
                  <label htmlFor="lastName">Last Name</label>
                  {formik.touched.lastName && formik.errors.lastName ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.lastName}
                             </div>
                               ) : null}
                </div>
               </div>
               <div className="d-flex justify-content-between">

               <div className=" user-box col-md-6 pe-2">
                <input name="email" value={formik.values.email} onBlur={formik.handleBlur} onChange={formik.handleChange} type="email" id="email"/>
                  <label htmlFor="email">Email</label>
                  {formik.touched.email && formik.errors.email ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.email}
                             </div>
                               ) : null}
                </div>

               <div className=" user-box col-md-6">
                  <input name="phone" value={formik.values.phone} onBlur={formik.handleBlur} onChange={formik.handleChange} type="text" id="phone"/>
                  <label htmlFor="phone">Phone Number</label>

                  {formik.touched.phone && formik.errors.phone ? (
                             <div className="alert alert-danger mt-2 p-2">
                             {formik.errors.phone}
                             </div>
                               ) : null}
                </div>

               </div>
              <div className="user-box position-relative">
                <input
                  name="password"
                  value={formik.values.password}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  className="form-control"
                  placeholder="Password"
                />
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary position-absolute end-0 top-50 translate-middle-y me-2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>

              </div>
              {formik.touched.password && formik.errors.password ? (
                  <div className="alert alert-danger mt-2 p-2">
                    {formik.errors.password}
                  </div>
                ) : null}
              <div className="user-box position-relative">
                <input
                  name="cPass"
                  value={formik.values.cPass}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type={showConfirmPassword ? "text" : "password"}
                  id="cPass"
                  className="form-control"
                  placeholder="Confirm Password"
                />
                <button
                  type="button"
                  className="btn btn-sm btn-outline-secondary position-absolute end-0 top-50 translate-middle-y me-2"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
               
              </div>
              {formik.touched.cPass && formik.errors.cPass ? (
                  <div className="alert alert-danger mt-2 p-2">
                    {formik.errors.cPass}
                  </div>
                ) : null}
              <button type="submit" className="button-style w-100">
              {loading ? (
                        <ClipLoader color="#ffffff" size={20} />
                      ) : (
                        " Create account"
                      )} 
              </button>
              <div className="text-center pt-2">
                  <p>
                  Already have an account?
                    <Link className="text-primary-me text-decoration-none" to='/login'><span> Login</span></Link>
                  </p>
                </div>
            </form>
          </div>
        </div>
        <div className="col-md-5 ">
          <SignUpSlider />
        </div>
      </div>
    </div>
  );
}
