import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signIn,signUp,GetAllRoomTypes, roomSearch, postReview, ConfirmEmail, UploadProfileImage, UpdateUser, ChangePassword, ForgetPass, RoomView } from "./api";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setRooms } from '../store/actions/roomActions';
export function useLogin(){
  const navigate = useNavigate()
  const queryClient = useQueryClient()
   return useMutation({
       mutationFn: (data)=> signIn(data),
       onMutate: ()=>{
         console.log('mutate');
       },
       onError: ()=>{
         console.log('error');
       },
       onSuccess: (response)=>{
         console.log('success',response);
       },
       onSettled:async (response,error)=>{
         console.log('settled');
        if (error) {
          console.log(error);
        }else{
          console.log(response);
          await queryClient.invalidateQueries({queryKey:['signIn']})
          navigate('/home')
        }
       },
   })
}

export function useSignUp(){
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation({
      mutationFn:(data)=> signUp(data),

      onMutate: ()=>{
        console.log('mutate');
      },
      onError: ()=>{
        console.log('error');
      },
      onSuccess: (response)=>{
        console.log('success',response);
      },
      onSettled:async (_,error)=>{
        console.log('settled');
       if (error) {
         console.log(error);
       }else{
        console.log('dsdsds');
         await queryClient.invalidateQueries({queryKey:['signUp']})
        //  navigate('/login')
       }
      },
  })
}
export function useRoomSearch(){
  const dispatch = useDispatch();
  return useMutation({
      mutationFn:(data)=> roomSearch(data),
      onMutate: ()=>{
        console.log('mutate');
      },
      onSuccess: (data) => {
      //  console.log('Room search successful:', data.data);
        dispatch(setRooms(data.data));
      },
      onError: (error) => {
        console.error('Room search failed:', error);
      }
  })
}
export function usePostReview(){
  return useMutation({
      mutationFn:(data)=> postReview(data),
  })
}

export function useConfirmEmail(){
  return useMutation({
      mutationFn:(data)=> ConfirmEmail(data),
      onMutate: ()=>{
        console.log('mutate');
      },
      onError: (error)=>{
        console.log('error',error);
      },
      onSuccess: (response)=>{
        console.log('success',response);
      },
      onSettled:async ()=>{
        console.log('settled');
      },
  })
}

export function useChangePassword(){
  const queryClient = useQueryClient()
  return useMutation({
      mutationFn:(data)=> ChangePassword(data),
      onMutate: ()=>{
        console.log('mutate');
      },
      onError: ()=>{
        console.log('error');
      },
      onSuccess: (response)=>{
        console.log('successw',response);
      },
      onSettled:async (_,error)=>{
        console.log('settled');
       if (error) {
         console.log(error);
       }else{
        console.log('dsdsds',);
         await queryClient.invalidateQueries({queryKey:['ChangePassword']})
       }
      },
  })
}

export function useUpdateUser(){
 const queryClient = useQueryClient()
 return useMutation({
     mutationFn:(data)=> UpdateUser(data),
     onMutate: ()=>{
       console.log('mutate');
     },
     onError: ()=>{
       console.log('error');
     },
     onSuccess: (response)=>{
       console.log('successw',response);
     },
     onSettled:async (_,error)=>{
       console.log('settled');
      if (error) {
        console.log(error);
      }else{
       console.log('dsdsds',);
        await queryClient.invalidateQueries({queryKey:['UpdateUser']})
      }
     },
 })
}

export const useUploadProfileImage = () => {
 const queryClient = useQueryClient()
 return useMutation({
     mutationFn:(data)=> UploadProfileImage(data),
     onMutate: ()=>{
       console.log('mutate');
     },
     onError: ()=>{
       console.log('error');
     },
     onSuccess: (response)=>{
       console.log('successw',response);
     },
     onSettled:async (_,error)=>{
       console.log('settled');
      if (error) {
        console.log(error);
      }else{
       console.log('dsdsds',);
        await queryClient.invalidateQueries({queryKey:['UploadProfileImage']})
      }
     },
 })
};
export function useForgetPass(){
  return useMutation({
      mutationFn:(data)=> ForgetPass(data),
      onMutate: ()=>{
        console.log('mutate');
      },
      onError: ()=>{
        console.log('error');
      },
      onSuccess: (response)=>{
        console.log('success',response);
      },
      onSettled:async ()=>{
        console.log('settled');
      },
  })
}

export function useViewRoom(){
  return useMutation({
      mutationFn:(data)=> RoomView(data),
  })
}
