import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const BookingCard = ({filterData,roomByFilter}) => {
  const location = useLocation();
  // Parse query parameters
  const queryParams = new URLSearchParams(location.search);
  const startDate = queryParams.get("start");
  const endDate = queryParams.get("end");   
  return (
    <div className="card p-3 shadow-sm bg-white w-75" >
      {/* Price Section */}
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div>
          <span className="fw-bold fs-4">${roomByFilter?.perNight}</span>
          <span className="text-muted"> /night</span>
        </div>
        <div>
          <span className="text-warning fw-bold">★ 4.8</span>
          <span className="text-muted ms-1">(256 reviews)</span>
        </div>
      </div>

      {/* Date and Guests */}
      <div className="d-flex justify-content-between bg-light p-2 rounded mb-3">
        <div>
          <div className="fw-bold">Date</div>
          <div className="text-muted">{startDate}</div>
        </div>
        <div>
          <div className="fw-bold">Guests</div>
          <div className="text-muted">{roomByFilter?.adult} adult</div>
          {roomByFilter?.child?<div className="text-muted">and {roomByFilter?.child} guests</div>:''
}
        </div>
      </div>
      <Link to={`/book-room/${roomByFilter?.hotelId}`} className="text-primary-me text-center fw-bold mb-3">See More Rooms</Link>

      {/* Check-in and Check-out */}
      <div className="mb-3">
        <label className="fw-bold mb-1">CHECK IN</label>
        <input
          type="date"
          className="form-control"
          defaultValue={startDate}
          disabled
        />
      </div>
      <div className="mb-3">
        <label className="fw-bold mb-1">CHECK OUT</label>
        <input
          type="date"
          className="form-control"
          defaultValue={endDate}
          disabled
        />
      </div>

      {/* Total Nights */}
      <div className="mb-4">
        <label className="fw-bold mb-1 d-block">TOTAL NIGHT</label>
        <p>{roomByFilter?.nights}</p>
      </div>

      {/* Total Price */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="fs-3 fw-bold">${roomByFilter?.total}</div>
        <div className="text-muted">Total for {roomByFilter?.adult} people</div>
      </div>

      {/* Book Now Button */}
      
        {roomByFilter?.available?<div className="py-2 text-end"><Link
                to={`/#}`}
                className="btn bgPrimaryColor text-white rounded-5 btn-lg custom-btn block"
              >
                Book Now
              </Link></div>:  
              <Link to={`/bookRoom/${roomByFilter?.hotelId}`} className="text-primary-me fw-bold mb-3">Sorry,this room is not available in this time See More Rooms</Link>
            }
    </div>
  );
};

export default BookingCard;
