import React from "react";
import { useGetHomeReviews } from "../../../services/queries";
import { BASE_URL } from "../../facilites/services/api";
import Loader from "../../loading/loadingPage";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import defaultImage from '../../../assets/Main/avatar.png'
export default function Reviews2() {
  const { data: reviews, isLoading } = useGetHomeReviews();

  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return <>
  <MDBContainer className="">
    <MDBCarousel showControls dark>
      {reviews.map((review) => (
        <MDBCarouselItem className="text-center" key={review.id}>
          <img
            src={review.imageUrl?BASE_URL+review.imageUrl:defaultImage}
            alt="avatar"
            className="rounded-circle shadow-1-strong mb-4"
            style={{ width: "100px", width: "120px" }}
          />
          <MDBRow className="d-flex justify-content-center">
            <MDBCol lg="8">
              <h5 className="mb-3">{review.fullName}</h5>
              <p className="text-muted mb-1">{review.nameEn}</p>
              <p className="text-muted">
                <MDBIcon fas icon="quote-left" className="pe-2" />
                {review.comment}
              </p>
              <p className="text-muted">
                {new Date(review.createdOn).toLocaleDateString()}
              </p>
            </MDBCol>
          </MDBRow>
          <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
            {Array.from({ length: 5 }).map((_, index) => (
              <li key={index}>
                <MDBIcon
                  fas
                  icon={index < review.rating ? "star" : "star-half-alt"}
                  size="sm"
                />
              </li>
            ))}
          </ul>
        </MDBCarouselItem>
      ))}
    </MDBCarousel>
  </MDBContainer>
  </>
   
}
