import React, { useEffect, useState } from "react";
import pool from "../../assets/facilites/image (15).png";
import pool2 from "../../assets/facilites/Rectangle 4 (2).png";
import pool3 from "../../assets/facilites/Rectangle 4 (2).png";
import pool4 from "../../assets/facilites/Rectangle 4 (2).png";
import { useGetPool } from "./services/queries";
import { useParams } from "react-router-dom";
import { BASE_URL } from "./services/api";
import "./facilites.css";
import Loader from "../loading/loadingPage";

export function PoolDetails() {
  const { id,hotelId } = useParams();
  const { data: poolDetails, isLoading } = useGetPool(id);
  const [clickedImage, setClickedImage] = useState(null);

  useEffect(() => {
    if (poolDetails && poolDetails.images && poolDetails.images.length > 0) {
      setClickedImage(poolDetails.images[0].url);
    }
  }, [poolDetails]);

  useEffect(()=>{
    if (hotelId==1) {
      document.title = poolDetails?.name + ' - Regency Plaza Aqua Park';
    }
    if (hotelId==2) {
      document.title = poolDetails?.name + ' - Royal Regency Club';
    }
    if (hotelId==3) {
      document.title = poolDetails?.name + ' - Romance Regency Club';
    }
 },[poolDetails]);
  if (isLoading) {
    return<Loader/>
  }
  return (
    <div className="container pt-5">
      <div className="row align-items-start pt-5">
        {/* Main Content Section */}
        <div className="col-12 col-md-8 mb-4 pt-5 ">
          {/* Main Image */}
          <div className="img-container">
          <img
            src={BASE_URL + clickedImage}
            alt="Main Pool Image"
            className="img-fluid rounded "
          />

          </div>
        </div>

        {/* Thumbnail Gallery */}
        <div className="col-12 col-md-4 pt-5">
          <div className="row">
            {poolDetails?.images?.map((img) => (
              <div
                key={img.url}
                onClick={() => setClickedImage(img.url)}
                className="mb-3 col-6 cursor-pointer "
              >
                <img
                  src={BASE_URL + img.url}
                  alt="regency pool"
                  className="img-fluid rounded thumbnail-container"
                />
              </div>
            ))}
          </div>
        </div>
        <div>
                   {/* Text Content */}
                   <div className="mt-4">
            <h2 className="fw-bold">{poolDetails?.name}</h2>
            <h5 className="fw-bold">Heated Pool</h5>
            <p>
              <i className="fa-solid fa-location-dot pe-2"></i>
              <strong>Location:</strong> Situated in the water park area.
            </p>
            <p>
              <i className="fa-solid fa-water-ladder pe-2"></i>
              <strong>Heated pool:</strong> with a heating system operational
              during the winter season.
            </p>
            <p>
              <i className="fa-solid fa-water pe-2"></i>
              <strong>Depth:</strong> Ranges from 100cm to 150cm.
            </p>

            <h5 className="fw-bold mt-4">Pool and Beach Towels:</h5>
            <p>
              <i className="fa-solid fa-person-swimming pe-2"></i>
              Use your Beach Towel Card to receive a free beach towel at any
              towel center on the resort.
            </p>

            <h5 className="fw-bold mt-4">Opening Hours:</h5>
            <ul>
              <li>
                <strong>In winter:</strong> All pools and the beach are open
                daily from{" "}
                <strong>
                  {poolDetails?.winterStartTime
                    ?.split(":")
                    .slice(0, 2)
                    .join(":")}
                </strong>{" "}
                to{" "}
                <strong>
                  {poolDetails?.winterEndTime?.split(":").slice(0, 2).join(":")}
                </strong>
                .
              </li>
              <li>
                <strong>In summer:</strong> All pools and the beach are open
                daily from{" "}
                <strong>
                  {poolDetails?.summerStartTime
                    ?.split(":")
                    .slice(0, 2)
                    .join(":")}
                </strong>{" "}
                to{" "}
                <strong>
                  {poolDetails?.summerEndTime?.split(":").slice(0, 2).join(":")}
                </strong>
                .
              </li>
            </ul>

            <h5 className="fw-bold mt-4">Caution:</h5>
            <ul>
              <li>
                <strong>Sun Loungers:</strong> Reserving sun loungers is
                prohibited; they are first-come, first-served.
              </li>
              <li>
                <strong>Attire:</strong> Guests must wear swimsuits in the pools
                and on the beach.
              </li>
              <li>
                <strong>Driving Equipment:</strong> It’s not allowed in the
                pools.
              </li>
              <li>
                <strong>Children:</strong> Those under 14 years old must be
                accompanied by an adult at the beach or pool.
              </li>
              <li>
                <strong>Health Advisory:</strong> Guests with stomach upset or
                diarrhea should avoid entering the water.
              </li>
              <li>
                <strong>Hygiene:</strong> Ensure children are taken to the
                bathroom and have their diapers changed every hour.
              </li>
            </ul>

            <h5 className="fw-bold mt-4">Emergency Assistance:</h5>
            <p>
              If injured, please call our 24-hour clinic or seek assistance from
              any Regency Plaza employee.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
